.RDWDetail .row {
  border-bottom: 1px solid #eee; }
  .RDWDetail .row span {
    margin-left: 1em; }

.RDWDetail span.-expired {
  color: red; }

.RDWDetail.ui.button,
.RDWDetail.ui.button:active,
.RDWDetail.ui.button:focus {
  background-color: #fff;
  color: #000; }

.RDWDetail.ui.button.buttonExpiredDate {
  box-shadow: inset 0 0 0 1px red !important;
  color: red !important; }
  .RDWDetail.ui.button.buttonExpiredDate:hover {
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important; }
