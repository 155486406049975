.ParkingGateCodes {
  &__table {
    .rt-tbody {
      overflow: hidden;
    }

    &-actions {
      text-align: right;
      padding-right: 1em;

      > button.ui.button {
        margin-left: 5px;
        border-radius: 0.28571429rem !important;

        > .icon {
          vertical-align: initial;
        }

        > .icon.edit {
          position: relative;
          bottom: 1px;
        }
      }
    }
  }

  .Table__no-results {
    position: relative;
    top: 20vh;
  }
}

.ParkingGateCodes__modal {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
