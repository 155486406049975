.TyreTeamConfigurationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.TyreTeamItem {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 8px;
  cursor: pointer;
}
