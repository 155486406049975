.appointment-date-container {
  div {
    display: inline-block;
  }

  div:last-child {
    font-weight: bold;
  }
}

.appointment-date-section .InlineInput .InlineInput__valueinput .appointment-date-picker-container {
  display: flex;
  align-items: center;

  .dateDiff {
    font-size: 1em;
    font-weight: 600;
    line-height: 1em;
    margin-right: 5px;
  }
}
