.Backorders {
  .ui.selection.active.wide-dropdown {
    .menu {
      min-width: fit-content;
    }
  }

  .sixteen.wide.column.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .BackorderDashboardTableContainer {
    max-width: 1920px;
    margin: 0 auto !important;
    padding: 0 0.25rem;
  }

  .export-button-container {
    display: flex;

    div {
      display: inline-block;
    }

    .export-button {
      padding: 9px;
    }
  }

  .BackorderDashboardTable {
    font-size: 1rem;
    line-height: 25px;

    .Table__no-results {
      margin-top: 100px;
    }

    .ReactTable.-floated-table {
      .rt-tbody,
      .rt-thead.-header {
        width: auto;
        margin: 0;
      }
    }

    .QuestionStatus {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 3px;
      float: right;
      text-align: center;
      width: 2.5em;

      & > span {
        color: #fff !important;
        margin-left: 4px;
      }
    }

    .rt-tbody {
      overflow: hidden !important;
      .rt-tr-group {
        cursor: initial !important;
      }
    }

    .pagination-bottom {
      .-pagination {
        .-next {
          button:enabled {
            &:hover {
              background-color: #377730;
              color: white;
            }
            background-color: #46923d;
            color: white;
          }
        }
        .-previous {
          button:enabled {
            &:hover {
              background-color: #377730;
              color: white;
            }
            background-color: #46923d;
            color: white;
          }
        }
      }
    }
  }

  .total-appointments {
    margin-left: 4px;
  }
}

.ExportLeads-Modal {
  .export-modal-content {
    text-align: center;
  }
}
