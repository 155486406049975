html {
  height: 100vh; }

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden; }

#root {
  height: 100vh; }

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important; } }

@page {
  margin: 20mm; }

@media print {
  .fix-printed-dimensions {
    width: 100% !important;
    height: auto !important; } }

@media print {
  .page-break-inside-avoid {
    page-break-inside: avoid !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  *:hover {
    cursor: default !important; }
  .ui.basic.button,
  .ui.basic.buttons .button {
    background: #fff !important;
    border-radius: 0 !important;
    border: 1px solid #eee;
    box-shadow: none !important;
    color: initial !important; }
    .ui.basic.button .icon,
    .ui.basic.buttons .button .icon {
      opacity: 1 !important; }
    .ui.basic.button:hover,
    .ui.basic.buttons .button:hover {
      box-shadow: none !important;
      color: initial !important;
      cursor: default !important; }
      .ui.basic.button:hover .icon,
      .ui.basic.buttons .button:hover .icon {
        opacity: 1 !important; }
  .AppointmentLists .AppointmentLists__list-title .icon:hover {
    cursor: default !important;
    transform: none !important; } }

.ExtendedSearchIcon {
  color: #eee;
  cursor: pointer;
  font-size: 1.3em !important;
  position: absolute;
  right: 1em;
  top: 1em; }
  .ExtendedSearchIcon:hover {
    color: #fff; }

.search .results {
  max-height: 400px;
  overflow-y: auto; }
  .search .results .message.empty .header {
    color: #3c3c3c;
    font-size: 2em;
    font-weight: 300;
    margin-top: 5em;
    text-align: center; }

.search.-fullscreen .results {
  border-radius: 0;
  border: none;
  font-size: 0.85em;
  height: calc(100% - 52px);
  min-height: calc(100% - 52px);
  position: fixed;
  top: 52px; }
  .search.-fullscreen .results .result {
    padding: 0; }

.search.-fullscreen.-results-with-header .results {
  height: calc(100% - 100px);
  min-height: calc(100% - 100px);
  top: 100px; }

.SearchResultsHeader {
  background-color: #d4d4d4;
  left: 0;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 100; }
  .SearchResultsHeader .column {
    padding-left: 10px !important; }

.ReactTable .rt-th {
  text-transform: capitalize; }

.ReactTable.-highlight {
  border: none !important;
  padding-bottom: 2em; }
  .ReactTable.-highlight .rt-thead.-header {
    background: padding-box;
    box-shadow: none !important;
    margin: 0 auto;
    padding: 5px 0px;
    width: 99%; }
    .ReactTable.-highlight .rt-thead.-header .ReactTable__column-header {
      border: none !important;
      font-size: 0.9em;
      font-weight: 600;
      text-align: left;
      text-transform: capitalize; }
    .ReactTable.-highlight .rt-thead.-header .ReactTable__column-header div {
      overflow: inherit !important; }
  .ReactTable.-highlight .rt-tbody {
    margin: 0 auto;
    width: 99%; }
    .ReactTable.-highlight .rt-tbody .rt-tr-group {
      background-color: #fff;
      border-radius: 1px;
      border: 1px solid #e4e4e4;
      color: #0c0c0c;
      font-size: 0.9em;
      min-height: 40px;
      margin-bottom: 1.5px; }
      .ReactTable.-highlight .rt-tbody .rt-tr-group .rt-tr .rt-td {
        border: none;
        color: #584a4a;
        padding-top: 0.5em; }
      .ReactTable.-highlight .rt-tbody .rt-tr-group:hover {
        background-color: #ededed;
        cursor: pointer; }
      .ReactTable.-highlight .rt-tbody .rt-tr-group .ReactTable__column {
        border-right: none; }
  .ReactTable.-highlight .rt-noData {
    background: transparent;
    font-size: 1.5em;
    top: 25%; }

.ReactTable.-floated-table {
  border: none !important;
  padding-bottom: 2em; }
  .ReactTable.-floated-table .rt-thead.-header {
    background: padding-box;
    box-shadow: none !important;
    margin: 0 auto;
    padding: 5px 0px;
    width: 99%; }
    .ReactTable.-floated-table .rt-thead.-header .ReactTable__column-header {
      border: none !important;
      font-size: 0.9em;
      font-weight: 600;
      text-align: left;
      text-transform: capitalize; }
    .ReactTable.-floated-table .rt-thead.-header .ReactTable__column-header div {
      overflow: inherit !important; }
  .ReactTable.-floated-table .rt-tbody {
    margin: 0 auto;
    width: 99%; }
    .ReactTable.-floated-table .rt-tbody .rt-tr-group {
      background-color: #fff;
      border-radius: 1px;
      border: 1px solid #e4e4e4;
      color: #0c0c0c;
      font-size: 0.9em;
      height: 40px;
      max-height: 40px; }
      .ReactTable.-floated-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
        border: none;
        color: #584a4a;
        padding-top: 0.5em; }
      .ReactTable.-floated-table .rt-tbody .rt-tr-group:hover {
        background-color: #ededed;
        cursor: pointer; }
      .ReactTable.-floated-table .rt-tbody .rt-tr-group .ReactTable__column {
        border-right: none; }
  .ReactTable.-floated-table .rt-noData {
    background: transparent;
    font-size: 1.5em;
    top: 25%; }
  .ReactTable.-floated-table.-no-click .rt-tr-group:hover {
    background-color: #fff;
    cursor: default; }

.ReactTable.-short-rows .rt-tr-group {
  height: 30px !important; }

.ReactTable.-scrollable .rt-tbody {
  overflow-y: auto !important; }

.Table__loading,
.Table__no-results,
.EmptyState {
  color: #828282;
  font-size: 1.75em;
  text-align: center; }

#nprogress .bar {
  background: #046b14 !important;
  height: 10px !important; }

@keyframes fadeIn {
  99% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

.-contained-large {
  max-width: 1920px;
  margin: 0 auto !important; }

.InlineInput .ui.input input {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  font-weight: 700 !important;
  padding-left: 0.2em; }

.InlineInput .react-datepicker-wrapper .react-datepicker__input-container input {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  font-weight: 700 !important;
  padding-left: 0.2em;
  padding: 0.67857143em 0.2em;
  width: 7em; }

.InlineInput button.ui.button {
  background: transparent;
  color: green;
  font-size: 1.25em;
  padding: 0; }
  .InlineInput button.ui.button:hover {
    background: transparent;
    color: #88ce17; }

.InlineInput .InlineInput__valueinput {
  display: inline-block; }

.CustomerInfoTrigger:hover {
  color: #046b14;
  cursor: pointer;
  transform: translateY(-0.5px); }

.RaketIndicator {
  background: #046b14;
  border-radius: 50%;
  bottom: 35px !important;
  height: 40px !important;
  left: 3em !important;
  width: 40px !important;
  z-index: 5000; }

.Loader-Placeholder {
  width: 100%;
  background: #f8f8f8;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  padding-top: 0.5em;
  text-align: center; }
  .Loader-Placeholder > div {
    width: 14px;
    height: 14px;
    background-color: gray;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .Loader-Placeholder .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .Loader-Placeholder .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }
  .Loader-Placeholder section {
    margin-top: 20px; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimm {
  display: flex !important;
  justify-content: center !important; }

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px; }
  .strength-meter:before, .strength-meter:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(20% + 6px);
    z-index: 10; }
  .strength-meter:before {
    left: calc(20% - 3px); }
  .strength-meter:after {
    right: calc(20% - 3px); }

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s; }
  .strength-meter-fill[data-strength="0"] {
    width: 20%;
    background: darkred; }
  .strength-meter-fill[data-strength="1"] {
    width: 40%;
    background: orangered; }
  .strength-meter-fill[data-strength="2"] {
    width: 60%;
    background: orange; }
  .strength-meter-fill[data-strength="3"] {
    width: 80%;
    background: yellowgreen; }
  .strength-meter-fill[data-strength="4"] {
    width: 100%;
    background: green; }

.Loader {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  padding-left: 50%;
  padding-top: 20%; }

.-text-elipsis {
  text-overflow: ellipsis; }

.react-alert-container > div {
  word-break: break-word; }
