.Kiosks {
  .ui.container {
    padding: 15px 0;
  }
}

.Kiosks,
.KioskLabelsModal {
  .ReactTable {
    min-height: 600px;

    .rt-thead .rt-th,
    .rt-thead .rt-td {
      padding-left: 0px;
    }

    .rt-tbody .rt-tr-group {
      cursor: auto !important;

      .rt-tr .rt-td {
        padding: 0px !important;
      }

      .rt-tr .rt-td:not(:first-child) {
        align-self: center;
      }
    }

    .kiosk-table-index {
      width: 50%;
      height: 100%;
      font-size: 1.3em;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .kiosk-box .icons {
      .icon {
        left: 45%;
        top: 52%;
      }
    }

    .kiosk-status-column {
      height: 100%;

      .-status-column_cell {
        height: 100%;
        padding-top: 7px;
        text-align: center;
      }
    }

    .kiosk-status-header {
      display: flex;
      justify-content: center;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .kiosk-actions-container {
      text-align: right;
      padding-right: 1em;

      button {
        margin-left: 5px;
        border-radius: 0.28571429rem !important;

        > .icon {
          vertical-align: initial;
        }

        > .icon.edit {
          margin-left: 2px !important;
        }
      }
    }
  }
}

.KioskPrivateKey-container {
  display: flex;
  cursor: pointer;
  font-size: 16px;
  justify-content: center;

  &:hover {
    color: green;
  }

  .actions button.ui.button {
    margin-right: auto;
  }
}

.ui.modal.KioskEditModal {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .KioskImageContainer {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
}
