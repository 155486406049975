.Customers .page-content {
  overflow-x: hidden; }

.Customers .results-msg {
  text-align: center;
  font-weight: 300;
  font-size: 18px; }

.Customers .person_placeholder {
  font-size: 200px !important;
  text-align: center;
  margin-top: 20%;
  width: 100%;
  color: #cacaca !important; }

.customer_detail_dialog .customer_sys_info {
  background-color: #efefef;
  border: 1px solid #e4e4e4;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 7px 14px; }

.customer_sys {
  margin: 1em 0 1em 1.5em; }
  .customer_sys .customer_sys_info {
    background-color: #eee;
    display: inline-block;
    margin-right: 1em;
    padding: 0.5em 1em; }

.Customers .lease-toggle {
  display: flex;
  justify-content: flex-end;
  margin-top: 7px !important;
  margin-right: 20px !important; }
  .Customers .lease-toggle label {
    font-weight: bold;
    margin-right: 10px !important; }

.Customers .lease-company-tag, .CustomerDetail .lease-company-tag {
  margin-right: 10px !important;
  background-color: #43ba45;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px; }
  .Customers .lease-company-tag i, .CustomerDetail .lease-company-tag i {
    margin-left: 3.5px !important; }
