.QuestionElements .Scrollbar {
  height: calc(100vh - 192px);
  overflow-y: auto; }

.QuestionElements .questionElementsItem {
  background-color: white;
  padding: 10px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 5px 5px 0 5px; }
  .QuestionElements .questionElementsItem .content {
    cursor: pointer;
    width: 100%;
    line-height: 40px !important; }
    .QuestionElements .questionElementsItem .content .ui.input {
      width: 75px;
      float: right;
      right: 25px; }
  .QuestionElements .questionElementsItem.active {
    border-color: green; }
