.FileDropDown {
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block; }
  .FileDropDown .Initiator {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-decoration-color: blue;
    text-decoration-style: dotted;
    text-decoration: underline;
    text-underline-position: under;
    transition: all 0.25s ease-in-out; }
    .FileDropDown .Initiator:hover {
      text-decoration-color: orange; }
  .FileDropDown .PositionLeft {
    right: 165px; }
  .FileDropDown .List {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #efefef;
    box-shadow: 0 8px 15px 0 #eee;
    padding: 20px 15px 12px 15px;
    position: absolute;
    transform: translateY(5px);
    width: 270px;
    z-index: 100; }
    .FileDropDown .List .Empty {
      text-align: center;
      font-size: 13px; }
    .FileDropDown .List .FileList {
      margin-top: 5px; }
    .FileDropDown .List .FileTitle {
      display: inline-flex;
      align-items: center;
      width: 100%; }
      .FileDropDown .List .FileTitle .ui.checkbox {
        margin-right: 2px; }
        .FileDropDown .List .FileTitle .ui.checkbox label::before {
          width: 15px;
          height: 15px;
          top: 2px; }
        .FileDropDown .List .FileTitle .ui.checkbox label::after {
          width: 15px;
          height: 15px;
          font-size: 11.5px;
          top: 1px; }
      .FileDropDown .List .FileTitle a {
        display: block;
        font-size: 0.75rem;
        margin: 7px 0 7px 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .FileDropDown .List .FileTitle a span {
          margin-right: 5px;
          display: inline; }
          .FileDropDown .List .FileTitle a span .icon {
            width: 10px;
            height: 10px;
            color: black; }
    .FileDropDown .List .FileAuthor,
    .FileDropDown .List .FileDate {
      display: block;
      color: #bababa;
      font-style: italic;
      font-size: 0.75rem; }
    .FileDropDown .List .FileName {
      display: inline-block;
      vertical-align: top;
      width: 85%; }
      .FileDropDown .List .FileName.-opened {
        font-weight: 700; }
    .FileDropDown .List .downloadButton {
      margin-top: 10px;
      width: 100%; }
    .FileDropDown .List .delete {
      font-size: 0.75rem;
      float: right;
      transform: translateY(-13px);
      cursor: pointer; }
    .FileDropDown .List .failed-icon {
      transform: translateY(-5px); }
    .FileDropDown .List .Adder {
      background-color: #0086d6;
      border-radius: 20px;
      border: 1px solid #eeee;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
      margin-top: 5px;
      padding: 8px 5px;
      transition: all 0.25s ease-in-out;
      width: 100%; }
      .FileDropDown .List .Adder:focus {
        outline: none; }
      .FileDropDown .List .Adder.dragOver {
        opacity: 0.3;
        border: 2px dotted #1f1f1f; }
    .FileDropDown .List .Closer {
      background-color: transparent;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      cursor: pointer;
      font-weight: 700;
      outline: none;
      position: absolute;
      right: 3px;
      top: 3px;
      color: #a09898; }
      .FileDropDown .List .Closer:hover {
        color: #000000; }
    .FileDropDown .List .failed-attachment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.75rem;
      width: 100%;
      padding: 7px 0px 5px 7px; }
    .FileDropDown .List .failed {
      color: red; }
    .FileDropDown .List .select-file-checkbox {
      display: inline-flex; }

.FilePreviewModal {
  width: 90% !important;
  position: relative; }
  .FilePreviewModal .FilePreviewHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px; }
    .FilePreviewModal .FilePreviewHeader > div:first-child {
      flex: 1;
      max-width: 70%;
      display: flex;
      align-items: center; }
    .FilePreviewModal .FilePreviewHeader > div:nth-child(2) {
      flex: 0 0 30%;
      display: flex;
      justify-content: flex-end; }
    .FilePreviewModal .FilePreviewHeader .FilePreviewName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      margin-left: 5px;
      font-weight: 400;
      font-size: 16px; }
  .FilePreviewModal .FilePreviewContent {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .FilePreviewModal .FilePreviewContent iframe {
      display: block;
      border: none;
      width: 100%;
      height: calc(100vh - 200px); }
    .FilePreviewModal .FilePreviewContent img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: fit-content; }
  .FilePreviewModal .FilePreviewContent:focus {
    outline: none; }
  .FilePreviewModal .left-arrow {
    position: absolute;
    left: calc(-5% + 15px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #d3d3d3;
    font-size: 40px;
    cursor: pointer; }
  .FilePreviewModal .right-arrow {
    position: absolute;
    right: calc(-5% + 15px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #d3d3d3;
    font-size: 40px;
    cursor: pointer; }
