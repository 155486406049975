.Pin-Container {
  display: inline-block;

  &.disabled {
    pointer-events: none;
  }

  .icon-margin-left {
    margin-left: 1.2px !important;
  }
}

.Pin-Modal {
  form.ui.form .align-right {
    display: flex;
    margin-left: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;

    .button {
      margin-left: auto;

      .icon {
        margin-right: 6px;
      }
    }
  }
}

.Pin-LogHistory {
  .log-values {
    margin-top: 4px;

    .ui.label {
      margin-bottom: 4px;

      svg.svg-inline--fa {
        margin-right: 0.75em;
      }

      .mark-fixed {
        color: rgb(23, 23, 166);
      }
    }
  }

  .headers {
    .column {
      font-weight: 700;
      font-size: 1rem;

      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  .content {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.3em;
    padding: 0;

    .inner-row .ui.grid {
      margin-top: 2px;

      .eye-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        .label {
          cursor: pointer;
        }

        .icon {
          margin: 0;
        }
      }
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
    }
  }
}

.all-attachments-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  margin: 20px 0px;
  justify-items: center;

  .attachment-container {
    width: 150px;

    .attachment-img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}
