.AccountSettings {
  background-color: #f7f7f7;
  height: 100%; }
  .AccountSettings .AccountSettings__picture {
    margin: 0 auto 1em;
    padding-top: 2em;
    text-align: center;
    width: 200px; }
    .AccountSettings .AccountSettings__picture:hover {
      opacity: 0.8; }
    .AccountSettings .AccountSettings__picture i {
      font-size: 8em !important; }
    .AccountSettings .AccountSettings__picture input {
      display: none; }
    .AccountSettings .AccountSettings__picture img {
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #959595;
      display: inline-block !important;
      height: 9em;
      width: 9em; }
  .AccountSettings .AccountSettings__content {
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: 5px 1px 9px #eee;
    margin: 0 auto;
    overflow-y: hidden;
    padding: 2em;
    width: 900px;
    margin-bottom: 16px; }
    .AccountSettings .AccountSettings__content-title {
      font-weight: 700;
      text-align: center;
      margin-bottom: 1.2em; }
    .AccountSettings .AccountSettings__content .custom-location-title {
      border-bottom: 1px solid #eee;
      color: #737373;
      font-weight: 300;
      margin-bottom: 0.5em;
      padding-bottom: 0.25em;
      text-align: center; }
    .AccountSettings .AccountSettings__content .ui.form .fields {
      padding: 0.5em 0; }
    .AccountSettings .AccountSettings__content .ui.form label {
      font-weight: 400; }
    .AccountSettings .AccountSettings__content .ui.form input {
      border-radius: 0; }
    .AccountSettings .AccountSettings__content .ui.form .error input {
      border-color: orange; }
    .AccountSettings .AccountSettings__content .ui.disabled.input {
      background-color: #f8f8f8;
      opacity: 1; }
    .AccountSettings .AccountSettings__content .AccountSettings__locations-assignable .label {
      border-radius: 0;
      padding: 0.75em 1.25em;
      margin: 0 0.5em 1em 0;
      border-radius: 4px; }
      .AccountSettings .AccountSettings__content .AccountSettings__locations-assignable .label label {
        margin-top: 1px;
        letter-spacing: 0.3px;
        display: inline-block;
        vertical-align: middle; }
  .AccountSettings .FormSection {
    margin-bottom: 50px; }
    .AccountSettings .FormSection.-desktop-notifications h3 {
      margin-bottom: 6px; }
    .AccountSettings .FormSection.-desktop-notifications p {
      margin-bottom: 20px; }
    .AccountSettings .FormSection-title {
      font-size: 20px;
      margin-bottom: 20px; }
    .AccountSettings .FormSection .field.form-field-custom-style label {
      font-weight: 500; }
    .AccountSettings .FormSection .my-role span {
      display: inline-block;
      margin-top: 10px;
      font-size: 20px;
      font-weight: 600; }
  .AccountSettings .ShoppingCart-container {
    width: 77%; }
    .AccountSettings .ShoppingCart-container .field {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-right: 55px !important; }
  .AccountSettings .ShoppingCart-container:last-child {
    width: 38.5%; }
  .AccountSettings .PinNotifications {
    margin-bottom: 15px; }
  .AccountSettings .DesktopNotifications h4,
  .AccountSettings .PinNotifications h4 {
    font-size: 16px;
    margin-bottom: 0; }
  .AccountSettings .DesktopNotifications .field label,
  .AccountSettings .PinNotifications .field label {
    width: 210px;
    display: inline-flex !important;
    align-items: center; }
    .AccountSettings .DesktopNotifications .field label .circular-icon,
    .AccountSettings .PinNotifications .field label .circular-icon {
      border-radius: 50%;
      font-size: 0.9em;
      height: 25px;
      line-height: 1em;
      margin: 0 6px 0 0;
      min-height: 25px;
      min-width: 25px;
      padding: 6px 0 0 0 !important;
      text-align: center;
      transform: none;
      width: 25px;
      background-color: #fff;
      border: 1px solid #d4d4d5; }
      .AccountSettings .DesktopNotifications .field label .circular-icon i.icon,
      .AccountSettings .PinNotifications .field label .circular-icon i.icon {
        margin: 0;
        width: auto; }
  .AccountSettings .DesktopNotifications .notification-checkbox-toggle,
  .AccountSettings .PinNotifications .notification-checkbox-toggle {
    display: flex; }
    .AccountSettings .DesktopNotifications .notification-checkbox-toggle .ui.checkbox.toggle,
    .AccountSettings .PinNotifications .notification-checkbox-toggle .ui.checkbox.toggle {
      vertical-align: middle; }
  .AccountSettings .DesktopNotifications .ui.dropdown .ui.label,
  .AccountSettings .PinNotifications .ui.dropdown .ui.label {
    height: 28px; }
    .AccountSettings .DesktopNotifications .ui.dropdown .ui.label svg.svg-inline--fa,
    .AccountSettings .DesktopNotifications .ui.dropdown .ui.label i.material-icons.-notification-status-icon,
    .AccountSettings .PinNotifications .ui.dropdown .ui.label svg.svg-inline--fa,
    .AccountSettings .PinNotifications .ui.dropdown .ui.label i.material-icons.-notification-status-icon {
      font-size: 18px;
      margin-right: 6px;
      vertical-align: middle;
      width: 18px; }
    .AccountSettings .DesktopNotifications .ui.dropdown .ui.label .car_out_of_shop_status_icon,
    .AccountSettings .PinNotifications .ui.dropdown .ui.label .car_out_of_shop_status_icon {
      height: 100%; }
  .AccountSettings .DesktopNotifications .ui.dropdown .ui.basic.label.notification-entity-label,
  .AccountSettings .PinNotifications .ui.dropdown .ui.basic.label.notification-entity-label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    vertical-align: middle; }
    .AccountSettings .DesktopNotifications .ui.dropdown .ui.basic.label.notification-entity-label svg,
    .AccountSettings .DesktopNotifications .ui.dropdown .ui.basic.label.notification-entity-label i,
    .AccountSettings .PinNotifications .ui.dropdown .ui.basic.label.notification-entity-label svg,
    .AccountSettings .PinNotifications .ui.dropdown .ui.basic.label.notification-entity-label i {
      display: inline-flex;
      justify-content: center;
      margin: 0 !important;
      padding: 0 !important; }
  .AccountSettings .DesktopNotifications .ui.dropdown .visible.menu .item svg,
  .AccountSettings .DesktopNotifications .ui.dropdown .visible.menu .item i.material-icons.-notification-status-icon,
  .AccountSettings .PinNotifications .ui.dropdown .visible.menu .item svg,
  .AccountSettings .PinNotifications .ui.dropdown .visible.menu .item i.material-icons.-notification-status-icon {
    font-size: 16px;
    margin-right: 6px;
    vertical-align: middle;
    width: 18px; }
  .AccountSettings .DesktopNotifications .ui.dropdown .visible.menu .item .car_out_of_shop_status_icon,
  .AccountSettings .PinNotifications .ui.dropdown .visible.menu .item .car_out_of_shop_status_icon {
    height: 14px; }
  .AccountSettings .DesktopNotifications .ui.dropdown .car_out_of_shop_status_icon,
  .AccountSettings .PinNotifications .ui.dropdown .car_out_of_shop_status_icon {
    position: relative;
    display: inline-block;
    width: 28px;
    vertical-align: middle; }
    .AccountSettings .DesktopNotifications .ui.dropdown .car_out_of_shop_status_icon i.sign-out,
    .AccountSettings .PinNotifications .ui.dropdown .car_out_of_shop_status_icon i.sign-out {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 8px; }
    .AccountSettings .DesktopNotifications .ui.dropdown .car_out_of_shop_status_icon svg,
    .AccountSettings .PinNotifications .ui.dropdown .car_out_of_shop_status_icon svg {
      position: absolute;
      font-size: 14px !important;
      left: 6px;
      bottom: 2px; }
  .AccountSettings .FixedButtonSection {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    box-shadow: 0px -2px 6px #00000029;
    background-color: #fff; }
    .AccountSettings .FixedButtonSection-inner {
      margin: 0 auto;
      padding: 0 2em;
      display: flex;
      justify-content: space-between;
      width: 900px;
      padding: 10px 2em; }
  .AccountSettings .ChangePasswordModal {
    left: 15% !important;
    margin: 0 !important;
    top: 3em !important;
    width: 70% !important; }
    .AccountSettings .ChangePasswordModal .actions {
      margin-bottom: 3em; }
  .AccountSettings .text-justify {
    text-align: justify;
    text-justify: inter-word; }
  .AccountSettings .form-field-custom-style {
    word-break: break-word; }
    .AccountSettings .form-field-custom-style .ui.toggle.checkbox {
      margin-left: 4px;
      margin-top: 8px;
      display: flex;
      align-items: center; }
    .AccountSettings .form-field-custom-style .ui.toggle.checkbox input:checked ~ .box:before,
    .AccountSettings .form-field-custom-style .ui.toggle.checkbox input:checked ~ label:before {
      background-color: #46923d !important; }
  .AccountSettings .DesktopNotifications-container .ui.segment {
    height: auto !important;
    padding: 1em !important;
    margin: 1rem 0 !important; }
