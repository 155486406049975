.AcsesDashboard {
  padding: 20px;

  h1 {
    color: #665e5e;
    margin: 10px 0px;
  }

  .ReactTable.-sharebox-logs-table {
    .rt-tbody {
      overflow-x: hidden;
    }
  }

  .AcsesClaireResponseIcon,
  .AcsesClaireResponseHeader {
    text-align: center !important;
  }
}
