.BrandDeleteLabel {
  margin: 0;
  transform: translateY(-2px); }
  .BrandDeleteLabel .icon {
    margin: 0 !important;
    opacity: 1 !important; }

.modal .logo {
  width: 200px;
  cursor: pointer;
  margin-right: 10px; }
