.ReplacementOfferTable.ReactTable {
  &.-floated-table .rt-thead.-header {
    width: 100%;

    .rt-th.-text-center-header {
      justify-content: center;
    }
  }

  .-text-center-column {
    text-align: center;
    margin-right: 15px;
  }

  .rt-thead .rt-th.-text-center-header {
    justify-content: center;
    font-weight: 600;
    margin-right: 15px;
  }

  .rt-tbody {
    overflow: hidden;
    width: 100% !important;
    min-height: 100px;
  }

  .replacement-tyre-ean {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .replacement-tyre-offer-input-field {
    .ui.form .field .ui.input input,
    .ui.form .fields .field .ui.input input {
      border-radius: 4px !important;
    }
  }

  .replacement-tyre-offer-price-input-field .ui.input > input {
    padding: 0.35em 1em;
    width: 100%;
  }

  .ui.grey.label.pmsf-icon-container {
    padding: 0.4628em 0.728em;

    img {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .pagination-bottom {
    .-pagination {
      .-next {
        button:enabled {
          &:hover {
            background-color: #377730;
            color: white;
          }
          background-color: #46923d;
          color: white;
        }
      }
      .-previous {
        button:enabled {
          &:hover {
            background-color: #377730;
            color: white;
          }
          background-color: #46923d;
          color: white;
        }
      }
    }
  }

  .-lower-customer-price {
    background-color: #fef0e7;
    color: #ff0202;
    width: fit-content;
  }

  .season-cell {
    display: flex;
  }
}

.ui.DeliveryDropdown.selection.dropdown .menu > .item {
  text-overflow: unset;
  white-space: normal;
}

.ui.fullscreen.modal.transition.visible.active.tyre-team-replacement-modal {
  top: auto !important;
  right: 0px !important;
  width: 98% !important;
  margin: auto !important;
}
