.InterventionQuickViewContent {
  max-height: 300px;
  overflow-y: auto;
  width: 700px;
  max-width: 700px;
}

.InterventionQuickView-interventions-container {
  width: 690px;
  max-width: 690px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 2px solid #d4d4d5;

  .title-description-container {
    width: 70%;
    display: flex;

    .title {
      width: 100%;
      font-size: 13px;
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  .special-indicators-container {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    margin: 0px;
    padding: 0px;

    .labor-minutes {
      margin-right: 25px;
      font-style: italic;

      svg {
        margin-right: 5px;
        font-size: 10px;
        position: relative;
        bottom: 1px;
        color: #414141;
      }
    }
  }

  .ThumbToggle {
    pointer-events: none;

    .icon {
      margin: 0px !important;
    }
  }

  .Pin-Container {
    margin-right: 5px;

    .ui.label {
      display: flex;
      justify-content: center;
      width: 32px;
    }

    i {
      margin: 0px !important;
    }
  }

  .AnswerReadyStatus {
    display: inline-block;
    margin-right: 5px;
    width: 18px;

    .ui.label {
      width: 0em;
    }
  }
}

.InterventionQuickView-interventions-container:last-child {
  border-bottom: none;
}
