.ReactTable .rt-thead {
  height: 3em; }

.ReactTable .rt-thead .rt-th {
  height: 2.8em;
  line-height: 2em;
  display: flex;
  justify-content: flex-start; }

.car-icons-column {
  height: 2.8em;
  line-height: 2em;
  display: flex;
  justify-content: flex-end !important; }

.ui.circular.label.keylocker-icon {
  background-color: #6435c9;
  color: white; }

.ReactTable.-appointments-table .rt-th > div:first-child {
  width: 100%;
  height: 100%; }

.ReactTable.-appointments-table .-table-header {
  width: 100%;
  display: inline-flex;
  min-width: 0; }
  .ReactTable.-appointments-table .-table-header .-table-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 4px;
    display: block;
    font-size: 12.6px;
    text-align: left; }
