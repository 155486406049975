.headerDanger {
  background-color: red;
  color: #fff;
  padding: 13px; }

.headerWarning {
  background-color: #ebb700;
  color: #fff;
  padding: 13px; }

.iconDanger {
  color: red;
  font-size: 3em; }

.iconWarning {
  color: #ebb700;
  font-size: 3em; }

.customButton {
  border: 1px solid #fff;
  border-radius: 0.6em;
  color: #fff !important; }

.confirmButtonDanger {
  background-color: red !important; }

.confirmButtonWarning {
  background-color: #ebb700 !important; }

.outer-content-box {
  border-radius: 0.6em;
  width: 100%;
  margin: 5% auto; }
  .outer-content-box .inner-content-box {
    display: flex;
    justify-content: center;
    align-items: center; }
    .outer-content-box .inner-content-box .confirm-msg {
      margin-left: 1em;
      width: 70%; }
  .outer-content-box .error-msg {
    display: flex;
    justify-content: center;
    font-size: 1em;
    color: red; }
