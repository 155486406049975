.Snooze-Container {
  display: inline-block; }

.Snooze-Modal .react-datepicker-wrapper {
  width: 100%; }

.Snooze-LogHistory .headers .column {
  font-weight: 700;
  font-size: 1rem; }
  .Snooze-LogHistory .headers .column:not(:first-child) {
    text-align: center; }

.Snooze-LogHistory .log_values {
  margin-top: 2px; }

.Snooze-LogHistory .content {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.3em;
  padding: 0; }
  .Snooze-LogHistory .content .inner-row .ui.grid {
    margin-top: 2px; }
  .Snooze-LogHistory .content .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px; }
