.TranslationsTable {
  max-width: 1920px;
  margin: 0 auto !important;
  font-size: 1rem;
  line-height: 25px; }
  .TranslationsTable .Table__no-results {
    margin-top: 50%; }
  .TranslationsTable .action-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 3px;
    float: right;
    text-align: center;
    width: 2.5em;
    cursor: pointer; }
    .TranslationsTable .action-icon > span {
      color: #fff !important;
      margin-left: 4px; }
  .TranslationsTable .edit-action {
    background-color: blue;
    color: #fff; }
  .TranslationsTable .delete-action {
    background-color: red;
    color: #fff; }
  .TranslationsTable .-disabled {
    pointer-events: none;
    opacity: 0.2; }
  .TranslationsTable .rt-tbody {
    overflow: hidden !important; }
    .TranslationsTable .rt-tbody .rt-tr-group {
      cursor: initial !important; }
  .TranslationsTable .pagination-bottom .-pagination .-next button:enabled {
    background-color: #46923d;
    color: white; }
    .TranslationsTable .pagination-bottom .-pagination .-next button:enabled:hover {
      background-color: #377730;
      color: white; }
  .TranslationsTable .pagination-bottom .-pagination .-previous button:enabled {
    background-color: #46923d;
    color: white; }
    .TranslationsTable .pagination-bottom .-pagination .-previous button:enabled:hover {
      background-color: #377730;
      color: white; }

.translations-toggle {
  display: flex;
  margin-top: 10px; }
  .translations-toggle label {
    font-weight: bold;
    margin-right: 10px; }
