.optional-services-container {
  border: 2px solid #ccc; }

.ui.grid > .row.optional-services-header-container {
  background-color: #78b3e5;
  padding: 0.55em 0.3em;
  align-items: center; }
  .ui.grid > .row.optional-services-header-container .optional-services-header-icon {
    color: #fff;
    margin-right: 0.35em;
    font-size: 1.2em; }
  .ui.grid > .row.optional-services-header-container .optional-services-header-title {
    color: #fff;
    font-size: 1.2em; }
  .ui.grid > .row.optional-services-header-container .optional-services-header-title-small {
    color: #fff;
    font-size: 0.8em;
    margin-right: 0.8em; }

.ui.grid > .row.optional-services-item {
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  padding: 0.3em 0em; }
  .ui.grid > .row.optional-services-item .optional-services-remark {
    font-weight: 600;
    color: #1c3e52; }

.remark__media {
  padding-top: 5px; }
  .remark__media .remark__media__Wrapper {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .remark__media .remark__media__Wrapper img {
      background-color: #eee;
      cursor: pointer;
      height: 150px;
      max-width: 266px; }
    .remark__media .remark__media__Wrapper section {
      background: #fff;
      border: 1px solid #eee;
      border-top: none;
      height: 2em;
      padding: 0.5em; }

.optional-services-item {
  align-items: center;
  padding: 0.3em 0em; }

.optional-services-item:last-child {
  border-bottom: none; }

.optional-services-action {
  margin-right: 1em; }
  .optional-services-action.disabled .ui.label {
    cursor: not-allowed; }
  .optional-services-action.no-cursor .ui.label {
    cursor: default; }
  .optional-services-action .ui.label {
    cursor: pointer;
    width: 40px;
    text-align: center;
    padding-top: 9px;
    padding-bottom: 9px; }

@media (max-width: 1500px) {
  .optional-services-action .ui.label {
    width: 35px; } }

.ui.grid > .row.client-remarks-item {
  margin: 0.3em;
  align-items: center;
  padding: 0.3em 0; }

.ui.grid > .row.client-remarks-item:last-child {
  margin: 0.3em 0.3em 2em 0.3em; }

.ui.grid > .row.client-remarks-header-container {
  background-color: #f7e99e;
  padding: 0.5em 0.3em;
  margin-bottom: 0.5em; }
  .ui.grid > .row.client-remarks-header-container .client-remarks-header-icon {
    color: #ce841d;
    margin-right: 0.35em;
    font-size: 1.1em; }
  .ui.grid > .row.client-remarks-header-container .client-remarks-header-title {
    color: #ce841d;
    font-size: 1em; }
  .ui.grid > .row.client-remarks-header-container .client-remarks-header-title-small {
    color: #fff;
    font-size: 0.8em; }

.client-remarks-subject {
  color: #000;
  font-weight: 600;
  margin-bottom: 1em; }

.client-remarks-text {
  color: #726c6c; }

.client-remarks-item {
  border: 1px solid #d9d9d9;
  border-radius: 0.3em;
  padding: 0.3em; }

.client-remarks-action {
  margin-right: 1em; }
  .client-remarks-action.disabled .ui.label {
    cursor: not-allowed; }
  .client-remarks-action .ui.label {
    cursor: pointer;
    width: 40px;
    text-align: center;
    padding-top: 9px;
    padding-bottom: 9px; }

@media (max-width: 1500px) {
  .client-remarks-action .ui.label {
    width: 35px; } }

.add-padding {
  padding: 0.35em 0.3em; }
