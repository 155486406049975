.DateOptionsPicker {
  display: inline-block; }
  .DateOptionsPicker .ui.selection.dropdown {
    float: left;
    min-width: 50%;
    width: 50%; }
    .DateOptionsPicker .ui.selection.dropdown .menu .item {
      text-transform: capitalize; }
  .DateOptionsPicker .DateOptionsPicker_datepicker-wrapper {
    float: left;
    width: 45%;
    margin-left: 3%; }
    .DateOptionsPicker .DateOptionsPicker_datepicker-wrapper input {
      -webkit-box-shadow: none;
      background: #fff;
      border: 1px solid #ececec;
      border-radius: 2px;
      box-shadow: none;
      color: rgba(0, 0, 0, 0.87);
      display: inline-block;
      min-height: 2.7em;
      min-width: 100%;
      padding-left: 10px;
      width: 100%; }
      .DateOptionsPicker .DateOptionsPicker_datepicker-wrapper input:focus {
        outline: none; }
      .DateOptionsPicker .DateOptionsPicker_datepicker-wrapper input:disabled {
        color: #dadada !important;
        border-color: #efefef; }
