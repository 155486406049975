.BoardItem-appointment-status {
  &:not(.-collapsed) {
    position: absolute;
    top: 0px;
    right: 0px;

    .-status-column_cell {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }

  .-status-column_cell {
    border-radius: 4px;
    box-shadow: -1px 1px 3px #00000029;
    height: 34px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa-envelope {
      font-size: 8px;
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: auto;
      left: auto;
    }

    svg {
      font-size: 20px;
    }

    .customer-answered-icon {
      margin-left: 0.4em;
      font-size: 15px;
    }

    .checkin-done-first-icon {
      font-size: 20px;
      margin-right: -2px;
    }

    .check-in-done-second-icon {
      position: absolute;
      font-size: 9px;
      top: -8px;
      right: -8px;
      bottom: auto;
      left: auto;
    }

    .car-in-shop-icon {
      margin-left: 0.1em;
      font-size: 20px;
    }

    .icons-container {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .car-ok-plus-repair-overview-second-icon {
      font-size: 10px;
      margin-bottom: 15px;
      margin-left: 25px;
    }

    .quality-check-plus-repair-overview-second-icon {
      font-size: 10px;
      margin-bottom: 15px;
      margin-left: 25px;
    }

    .car-check-started-icon {
      margin-left: 0.1em;
      font-size: 20px;
    }

    .back-order-icon {
      font-weight: 600;
      font-size: 18px;
    }

    .car-out-of-shop-icon {
      font-size: 9px;
      position: absolute;
      bottom: 6px;
      left: 1.5px;
    }
  }

  .status-column-icons-container {
    position: relative;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0em;
  }

  .-status-column_cell.status-column-icons-container.composite-icon {
    .material-icons {
      font-size: 21px;

      &[title="Info"] {
        position: relative;
        bottom: 1px;
      }
    }

    svg.svg-inline--fa.fa-car-alt.fa-w-15.new-car-icon {
      font-size: 18px;
    }

    .fa-layers {
      width: 100%;
      height: 100%;
      position: relative;

      .check-in-done-second-icon {
        position: absolute;
        font-size: 9px;
        top: 2px;
        right: 1px;
        bottom: auto;
        left: auto;
      }
    }

    span.fa-layers.icons-container.backorder-composite {
      position: relative;
      top: -1px;

      span {
        font-size: 15px;
      }
    }
  }
}
