.Checklist .TopHeader {
  padding: 20px; }

.Checklist .Scrollbar {
  height: calc(100vh - 192px);
  overflow-y: auto; }

.Checklist .ListItem {
  border-radius: 5px;
  border: 1px solid lightgray;
  border-left: 4px solid gray;
  margin: 5px 0 5px 2px;
  width: 100%;
  padding: 10px;
  opacity: 0.5; }
  .Checklist .ListItem * {
    pointer-events: none; }
  .Checklist .ListItem .header {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray; }
  .Checklist .ListItem .description {
    margin-top: 10px; }
  .Checklist .ListItem:hover, .Checklist .ListItem.active {
    cursor: pointer;
    border-left: 4px solid red;
    background-color: white;
    opacity: 1; }
  .Checklist .ListItem.hover {
    background-color: #eaeaea; }
  .Checklist .ListItem .content {
    padding: 20px; }

.Checklist .ChecklistItem {
  background-color: white;
  padding: 10px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 5px 5px 0 5px; }
  .Checklist .ChecklistItem .content {
    width: 100%;
    line-height: 40px !important; }
    .Checklist .ChecklistItem .content .ui.input {
      width: 75px;
      float: right;
      right: 25px; }
  .Checklist .ChecklistItem.green {
    border-left: 4px solid green; }
  .Checklist .ChecklistItem.red {
    border-left: 4px solid red; }
  .Checklist .ChecklistItem .checklist_tag {
    color: white;
    margin-left: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px 7px 3px 5px; }
