.Appointments {
  height: 100%;
  min-height: 95vh; }
  .Appointments .SubHeader .SubHeader_content .DateOptionsPicker {
    float: right;
    display: inline-block;
    width: 50%; }
  .Appointments .SubHeader .SubHeader_content .icon.chart, .Appointments .SubHeader .SubHeader_content .icon.add {
    cursor: pointer;
    font-size: 1.75em;
    margin-right: 15px;
    transform: translateY(7px); }
    .Appointments .SubHeader .SubHeader_content .icon.chart:hover, .Appointments .SubHeader .SubHeader_content .icon.add:hover {
      color: green; }
  .Appointments .ReactTable {
    margin-top: 5px !important;
    min-height: calc(100% - 2em); }
    .Appointments .ReactTable .rt-thead.-header .total-appointments {
      margin-left: 4px; }
    .Appointments .ReactTable .rt-tbody {
      min-height: 100vh; }
      .Appointments .ReactTable .rt-tbody .rt-tr-group {
        height: 35px !important;
        max-height: 35px !important;
        overflow: hidden; }
        .Appointments .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
          padding-top: 0.65em; }
    .Appointments .ReactTable .rt-thead .rt-th.car-icons-column > div {
      width: 100%; }
  .Appointments .wo-nr-container {
    margin-left: 5px;
    display: flex; }
    .Appointments .wo-nr-container .appointment-pin-container {
      position: relative;
      right: 3px;
      color: initial; }
    .Appointments .wo-nr-container .internal-wo {
      position: relative;
      bottom: 4.5px;
      margin-left: -5px;
      background-color: #fef0e7;
      color: #fe0202;
      font-weight: bold;
      padding: 5px;
      border-radius: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .Appointments .wo-nr-container .wo-nr-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl; }
  .Appointments .-reg-no-column:hover {
    color: green !important;
    font-weight: 600; }
  .Appointments .-checklists-column .AppointmentChecklistInitial {
    background: #fff;
    border-radius: 2px;
    border: 1.3px solid green;
    color: green;
    display: inline-block;
    font-weight: 700;
    margin-right: 4px;
    min-width: 22px;
    text-align: center; }
  .Appointments .-car-model-column .-car-model-column-wrapper {
    position: relative;
    display: flex; }
    .Appointments .-car-model-column .-car-model-column-wrapper span.-car-model {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .Appointments .-car-model-column .-car-model-column-wrapper .AppointmentSpecialIndicators {
      position: absolute;
      right: 0; }
  .Appointments .-status-column {
    height: 35px;
    padding: 0 !important; }
    .Appointments .-status-column .-status-column_cell {
      color: #000;
      font-size: 0.9em;
      height: 33px;
      padding-top: 5px;
      text-align: center; }
  .Appointments .ChecklistInitials {
    padding-right: 20px; }
  .Appointments .LocationList {
    display: inline-block;
    transform: translateY(7px); }
    .Appointments .LocationList span {
      border: 1px solid #46923d;
      color: #46923d;
      font-size: 13px;
      letter-spacing: 1px;
      margin-right: 6px;
      padding: 6px 9px; }
  .Appointments .-panic-user {
    background-color: #fef0e7;
    color: #ff0202;
    padding: 5px; }
  .Appointments .-customer-sign-wo {
    background-color: #e1ecf3;
    color: #2d85d0;
    padding: 5px; }
  .Appointments .-warranty-signature-missing {
    padding: 5px;
    background-color: #fef0e7;
    color: #ff0202; }
  .Appointments .customer-sign-placeholder {
    height: 100%;
    position: relative;
    bottom: 1px; }

.AppointmentSpecialIndicators {
  display: inline-block;
  float: right;
  text-align: right;
  transform: translateY(-3px); }
  .AppointmentSpecialIndicators .ui.circular.label {
    border-radius: 50%;
    float: left;
    font-size: 0.9em;
    height: 25px;
    line-height: 1em;
    margin: 0 2px 0 0 !important;
    min-height: 25px;
    min-width: 25px;
    padding: 6px 0 0 0 !important;
    text-align: center;
    transform: none;
    width: 25px; }
    .AppointmentSpecialIndicators .ui.circular.label.checkedTire {
      overflow: hidden; }
      .AppointmentSpecialIndicators .ui.circular.label.checkedTire .checkedTire-tire {
        position: relative;
        left: 5px;
        top: 3px;
        font-size: 25px; }
      .AppointmentSpecialIndicators .ui.circular.label.checkedTire .checkedTire-check {
        position: relative;
        top: -17px;
        left: -21px;
        font-size: 11px; }
    .AppointmentSpecialIndicators .ui.circular.label.bigTire {
      font-size: 1.5em;
      padding: 3px 0 0 0 !important; }
      .AppointmentSpecialIndicators .ui.circular.label.bigTire svg {
        width: 25px; }
    .AppointmentSpecialIndicators .ui.circular.label i.icon {
      margin: 0; }
    .AppointmentSpecialIndicators .ui.circular.label i.material-icons {
      font-size: 1em;
      padding: 0 1px; }
  .AppointmentSpecialIndicators .ui.label.kiosk-label-icon {
    display: inline-flex;
    justify-content: center;
    width: 40px;
    border-radius: 5px; }
    .AppointmentSpecialIndicators .ui.label.kiosk-label-icon span {
      margin-left: 5px; }
  .AppointmentSpecialIndicators .customer-com .smile.icon {
    font-size: 1.3em;
    transform: translateY(1px); }
  .AppointmentSpecialIndicators-Header {
    position: relative;
    font-size: 12.6px;
    font-weight: 600;
    right: 3px; }
  .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter {
    border-width: 3px !important;
    line-height: 0.9em !important; }
    .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter.green {
      border-color: #21ba45 !important; }
    .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter.blue {
      border-color: #2185d0 !important; }
    .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter.orange {
      border-color: #f2711c !important; }
    .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter.red {
      border-color: #db2828 !important; }
    .AppointmentSpecialIndicators .ui.circular.label.custom-shop-icon-filter.yellow {
      border-color: #fbbd08 !important; }
  .AppointmentSpecialIndicators .ui.grey.circular.label.custom-shop-icon-filter.yellow,
  .AppointmentSpecialIndicators .circular.label.custom-shop-icon-filter.yellow {
    width: 28px;
    height: 28px;
    bottom: 2px;
    position: relative;
    line-height: 1em !important; }

.AppointmentSpecialIndicators-AppointmentNotePopup .AppointmentNoteTitle {
  display: flex;
  margin-bottom: 3px;
  align-items: center; }
  .AppointmentSpecialIndicators-AppointmentNotePopup .AppointmentNoteTitle svg {
    position: relative;
    margin-right: 7px;
    bottom: 1px; }

.AppointmentSpecialIndicators-AppointmentNotePopup .AppointmentNoteDescription {
  font-style: italic;
  font-size: 12px;
  color: #767676; }

.AppointmentSpecialIndicators-AppointmentNotePopup .AppointmentNoteContent {
  margin-top: 10px;
  font-size: 14px; }
  .AppointmentSpecialIndicators-AppointmentNotePopup .AppointmentNoteContent .CallCustomerPhoneNr {
    margin-bottom: 6px; }

.AppointmentDetailModal.ui.modal {
  border-radius: 0;
  height: 100%;
  margin-top: 1.5rem !important;
  top: 0; }

.modals.dimmer .ui.fullscreen.modal {
  left: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  width: 100% !important; }

.modals.dimmer .ui.scrolling.modal.AppointmentDetailModal {
  margin: 0 !important; }

.AppointmentStatusSelector.ui.dropdown {
  border-radius: 4px;
  font-weight: 700;
  padding: 0.75em 0;
  text-align: center;
  width: 4em; }
  .AppointmentStatusSelector.ui.dropdown .dropdown.icon {
    display: none; }
  .AppointmentStatusSelector.ui.dropdown .icon {
    margin: 0; }

.AddAppointmentModal {
  left: 15% !important;
  margin: 0 !important;
  top: 3em !important;
  width: 70% !important; }

.SearchResult-Title {
  float: left;
  font-size: 0.9em;
  width: 30% !important; }

.SearchResult-RegNumber:hover {
  color: #46923d; }

.SearchResult-Detail {
  float: right;
  font-size: 0.8em;
  text-align: right;
  width: 67%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px; }
  .SearchResult-Detail .StatusIcon {
    border-radius: 2px;
    margin-left: 2px;
    padding: 3px 6px; }
    .SearchResult-Detail .StatusIcon svg {
      font-size: 0.6rem; }
  .SearchResult-Detail .-status-column_cell {
    border-radius: 2px;
    margin-left: 2px;
    padding: 4px 8px;
    display: inline-flex !important;
    align-items: center;
    min-width: 28px;
    height: 22px; }
    .SearchResult-Detail .-status-column_cell span.fa-layers {
      height: 20px !important;
      width: 20px !important; }
    .SearchResult-Detail .-status-column_cell svg.fa-calendar-check {
      position: relative;
      top: -2px;
      left: 7px;
      font-size: 15px !important; }
    .SearchResult-Detail .-status-column_cell svg {
      padding: 2px 4px;
      margin: 0 !important; }
    .SearchResult-Detail .-status-column_cell i.material-icons {
      transform: none;
      font-size: 14px; }
    .SearchResult-Detail .-status-column_cell .back-order-status-icon {
      font-size: 12px !important; }
    .SearchResult-Detail .-status-column_cell i.icon.sign-out {
      bottom: 3px !important;
      left: 1px !important;
      font-size: 7px; }
  .SearchResult-Detail i.material-icons {
    font-size: 1em;
    transform: translateY(2px); }
  .SearchResult-Detail .importing-keyloop-appointment-loader-container {
    float: right;
    width: 15px;
    vertical-align: baseline;
    margin-left: 3px; }

.ReportsModal {
  height: 90%;
  left: 5% !important;
  margin-left: 0 !important;
  width: 90% !important; }
  .ReportsModal .content {
    height: 90%; }

.Appointments__SnoozedItems .ReactTable.-floated-table {
  padding-bottom: 0; }

.ui.white.label,
.ui.white.labels .label {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #fff !important; }

.Header__search .ui.category.search > .results .category .name {
  padding: 0.4em 0.6em;
  vertical-align: middle; }

.Header__search .ui.category.search > .results .result {
  padding: 7px 7px;
  font-size: 0.9em;
  line-height: 1.25rem; }

.ui.search > .results .result.search-results-divider {
  width: 100%;
  border-top: 1px solid #cececf;
  border-bottom: 1px solid #cececf;
  padding: 6px 0;
  text-align: center;
  pointer-events: none;
  color: #767676;
  font-weight: 700; }
