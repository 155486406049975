.CarDetailModal {
  height: 100%;
  padding-left: 35px;
  padding-right: 35px; }
  .CarDetailModal.ui.modal .header {
    border-bottom: 0px;
    padding-bottom: 0px; }
    .CarDetailModal.ui.modal .header .CarDetailMenu {
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 5px 10px 1px 10px;
      justify-content: flex-start; }
      .CarDetailModal.ui.modal .header .CarDetailMenu .active {
        border-width: 4px; }
  .CarDetailModal.ui.modal .content.scrolling {
    position: relative;
    padding-top: 0px;
    max-height: calc(100vh - 10px - 3.285714em - 8.58571429rem); }
    .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer {
      width: 100%;
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 20px; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer h3 {
        margin-top: 8px;
        margin-bottom: 2rem; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .DataLoader {
        width: 100%;
        text-align: center; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .NoDataAvailable {
        border: 1px solid #eee;
        text-align: center;
        padding: 0.75em;
        margin-bottom: 1em; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column {
        padding-left: 0.3rem;
        padding-right: 0.1rem; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column:first-of-type {
          color: #ccc;
          font-size: 7em;
          padding: 0 !important;
          text-align: center; }
          .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column:first-of-type img {
            width: 100%;
            height: auto; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column:last-of-type {
          border-right: none; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column span .InfoType {
          display: block;
          font-size: 1em;
          font-weight: 600;
          margin-left: 1.5rem;
          margin-bottom: 3px;
          margin-right: 1rem; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarDetailModal__Info .column span .InfoValue {
          display: flex;
          margin-left: 1.5rem;
          font-size: 1.3em; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Header {
        background-color: #007f01;
        color: #fff; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Header > .column:not(.row) {
          padding: 0.6rem 0.4rem 0.6rem 0.8rem; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Item {
        border: 1px solid #eee;
        margin-bottom: -1px; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Item:hover {
          background-color: #f7f7f7; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Item .icon:hover {
          cursor: pointer; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarSnoozedItems__List .CarSnoozedItems__List__Item > .column:not(.row) {
          padding: 0.4rem 0.4rem 0.4rem 0.8rem; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Header {
        color: #000;
        background-color: #f4f4f4;
        border-top: 2px solid #1dbf5e;
        font-size: 0.9em; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Header .column:not(:first-child) {
          text-align: right; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Header .CarAppointmentHistory__Property {
          margin-right: 5px; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Header .CarAppointmentHistory__Property-Value {
          font-weight: 600; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks-None {
        padding: 1rem;
        text-align: center; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks .CarAppointmentHistory__List__Item__Checks__Header {
        background-color: #f4f4f4;
        border-bottom: 1px solid #eee;
        padding: 0.5em 0;
        font-weight: 600; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks .CarAppointmentHistory__List__Item__Checks__Header .icon {
          font-size: 1.5em; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks .CarAppointmentHistory__List__Item__Checks__Row {
        border-bottom: 1px solid #eee; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks .CarAppointmentHistory__List__Item__Checks__Row:last-of-type {
          border-bottom: none; }
        .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__List__Item__Checks .CarAppointmentHistory__List__Item__Checks__Row .column:last-child {
          margin-left: 55px; }
      .CarDetailModal.ui.modal .content.scrolling .CarDetailContainer .CarAppointmentHistory .CarAppointmentHistory__List__Item .CarAppointmentHistory__list__Item__appointment_canceled {
        padding: 1rem;
        text-align: center; }
  .CarDetailModal .ServiceBox-Widget {
    overflow: hidden;
    width: 100%; }
  .CarDetailModal .ServiceBox-Widget-Iframe {
    height: 40px;
    margin: 0 3px 0 2px !important;
    overflow: hidden;
    width: 100%; }

.FileDropDownCarDetail {
  float: right; }
  .FileDropDownCarDetail .ui.blue.large.label {
    text-align: center;
    height: 37px;
    line-height: 23px;
    width: 60px; }
    .FileDropDownCarDetail .ui.blue.large.label i {
      margin-right: 0; }
