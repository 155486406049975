.PublicAPI-DealerAdminDashboard {
  padding-bottom: 40px; }

.API-Agreement {
  background: #fff;
  border: 1px solid #eee;
  left: 25%;
  margin: 0 auto;
  padding: 30px;
  width: 50%; }
  .API-Agreement p {
    height: 500px;
    overflow-y: auto;
    padding: 20px; }
  .API-Agreement section {
    text-align: center; }
    .API-Agreement section button {
      margin: 20px !important;
      width: 150px; }

.public-api-text-area {
  width: 100%;
  margin-bottom: 10px;
  resize: none;
  border-color: #ccc; }
  .public-api-text-area:focus {
    border-color: #85b7d9 !important;
    outline: #85b7d9; }
