#desk-communication-agreement-content {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;

  @media print {
    padding: 1rem;
  }

  .ui.secondary.menu {
    display: table;
    width: 100% !important;

    a.item {
      display: inline-block;
      padding: 1.2% !important;
    }
  }

  .info-section {
    border: 2px solid rgba(187, 194, 198, 0.75);
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    .section-header {
      background-color: #f3f5f7;
      font-weight: 700;
      padding: 1em;

      @media print {
        position: relative;
        z-index: -1;
      }
    }

    .section-content {
      .agreements-label {
        display: block;
        margin-bottom: 4px;

        span {
          cursor: pointer;
        }

        .ui.label {
          cursor: pointer;

          i.icon {
            margin: auto;
          }
        }
      }

      .signature-img {
        background-color: #f3f5f7;
        padding: 17px 15px;
      }

      .event-timestamp {
        text-align: right;
      }

      .services-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.8rem;
        font-weight: 700;

        @media print {
          width: 100% !important;

          span {
            width: auto !important;
          }
        }
      }

      .service {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;
        padding-bottom: 0.8rem;
        border-bottom: 1px solid #f3f5f7;

        @media print {
          width: 100% !important;
        }

        .service-title-remark-container {
          display: flex;
          flex-direction: column;
          white-space: normal;
          word-wrap: break-word;
          width: 88%;

          @media print {
            padding-right: 50px;
          }

          span:first-child {
            flex: 1;
            white-space: normal;
            word-wrap: break-word;
            font-weight: bold;
          }
        }

        .service-price-container {
          white-space: nowrap;
          margin-left: 10px;
          width: 22%;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      span.icon-text {
        color: #21ba45;
        font-weight: 700;
      }
    }
    .section-rows {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 0.5rem;

      @media print {
        width: 100% !important;
      }

      & > span:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.DeskCommunicationLog-Modal {
  &.ui.modal {
    > .actions {
      display: flex;
    }
    > .header {
      display: flex;

      .print-btn {
        margin-left: auto;
      }
    }
  }
}

.comm-agreement-label {
  cursor: pointer;
}
