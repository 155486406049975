@page {
  margin: 36px 32px !important; }

#dbb_label_content_container {
  width: 100% !important;
  padding: 20px;
  margin: 0; }
  @media print {
    #dbb_label_content_container {
      padding: 0 !important; } }
  #dbb_label_content_container * {
    font-family: Arial, Helvetica, sans-serif; }
  #dbb_label_content_container div {
    font-size: 20px;
    line-height: 32px; }
  #dbb_label_content_container .dbb_wrapper {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  @media print {
    #dbb_label_content_container .dbb_wrapper_label_size {
      display: block !important;
      height: 100% !important; } }
  @media print {
    #dbb_label_content_container .dbb_wrapper_label_size > div {
      height: fit-content !important; } }
  @media print {
    #dbb_label_content_container .dbb_wrapper_label_size > div div {
      height: 100% !important; } }
  #dbb_label_content_container .dbb_label_box {
    margin: 0 35px 242px 35px; }
    #dbb_label_content_container .dbb_label_box:nth-last-child(-n + 2) {
      margin-bottom: 0; }
    #dbb_label_content_container .dbb_label_box .dbb_label_content {
      padding-right: 40px; }
      #dbb_label_content_container .dbb_label_box .dbb_label_content .dbb_label_section div {
        line-height: 27px; }
      #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-28 {
        margin-bottom: 28px; }
      @media print {
        #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-28_label_size {
          margin-bottom: 14px !important;
          height: 30px !important; } }
      #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-26 {
        margin-bottom: 25px; }
      @media print {
        #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-26_label_size {
          margin-bottom: 0px !important; } }
      #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-24 {
        margin-bottom: 20px; }
      @media print {
        #dbb_label_content_container .dbb_label_box .dbb_label_content .mb-24_label_size {
          margin-bottom: 0px !important; } }
    @media print {
      #dbb_label_content_container .dbb_label_box .dbb_label_content_label_size {
        padding-right: 0 !important;
        width: auto !important; } }
    #dbb_label_content_container .dbb_label_box .dbb_label_name {
      color: #4e4e4e; }
    #dbb_label_content_container .dbb_label_box .dbb_label_comment {
      display: flex;
      justify-content: space-between;
      height: 60px;
      overflow: hidden; }
    #dbb_label_content_container .dbb_label_box .dbb_section_mid {
      line-height: 27px; }
    #dbb_label_content_container .dbb_label_box .dbb_location_name {
      display: inline-block;
      height: 65px;
      max-height: 65px;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 85px; }
    #dbb_label_content_container .dbb_label_box:nth-child(even) {
      margin-right: 0; }
      #dbb_label_content_container .dbb_label_box:nth-child(even) .dbb_label_tyre_position span {
        margin-right: 3px; }
    #dbb_label_content_container .dbb_label_box:nth-child(odd) {
      margin-left: 0; }
      #dbb_label_content_container .dbb_label_box:nth-child(odd) .dbb_label_tyre_position span {
        margin-right: 1ch; }
  @media print {
    #dbb_label_content_container .dbb_label_box_label_size {
      width: 100% !important;
      height: auto !important;
      margin: 0px !important;
      page-break-after: always; } }
  #dbb_label_content_container h1 {
    width: 100% !important;
    font-size: 68px;
    font-weight: 900;
    margin-bottom: 20px;
    text-align: center; }
  @media print {
    #dbb_label_content_container .h1_label_size {
      margin-bottom: 0px !important; } }
  #dbb_label_content_container .dbb_label_tyre {
    display: grid;
    grid-template-areas: "a b" "c b"; }
    #dbb_label_content_container .dbb_label_tyre_position {
      grid-area: a;
      font-weight: 600;
      font-size: 24px; }
      #dbb_label_content_container .dbb_label_tyre_position span {
        text-decoration: underline; }
    #dbb_label_content_container .dbb_label_tyre_size {
      grid-area: c;
      font-size: 20px;
      font-weight: 400; }
    #dbb_label_content_container .dbb_label_tyre_description {
      grid-area: b;
      margin-left: 20px;
      font-weight: 600;
      font-size: 24px; }
  #dbb_label_content_container .float-right {
    float: right;
    text-align: right;
    width: auto !important; }

.TyreAnswer .dbb_storage_location {
  float: right;
  margin-top: 6px;
  margin-right: 5px;
  font-weight: bold; }
  .TyreAnswer .dbb_storage_location span {
    text-transform: none;
    color: #4da550; }

.TyreAnswer .TyreAnswerTable__column.tyre_brand_column {
  text-overflow: ellipsis;
  overflow: hidden; }

.TyreAnswer .TyreAnswerTable__column .ui.label.-order-disabled {
  cursor: default !important;
  pointer-events: none; }

@media print {
  .TyreAnswer .dbb_storage_location_label_size {
    margin-top: 0px !important; } }

.dbb_label_dropdown {
  display: inline-block; }
  .dbb_label_dropdown span {
    margin-right: 10px;
    font-size: 14px;
    color: #5d5d5d;
    vertical-align: middle; }
