.Timer .Timer__Slot {
  min-width: 40px;
  text-align: center;
  padding: 5px 15px;
  background-color: rgba(107, 193, 107, 0.82);
  color: #fff;
  margin: 0;
  float: left;
  border-left: 1px solid #6dc06c;
  font-weight: 300;
  letter-spacing: 0.5px; }
  .Timer .Timer__Slot:first-child {
    border-bottom-left-radius: 3px;
    border-left: none;
    border-top-left-radius: 3px; }
  .Timer .Timer__Slot:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px; }
  .Timer .Timer__Slot.timeslot-soon {
    background-color: #5da6e4;
    border-color: #5992c4;
    font-weight: 600; }
  .Timer .Timer__Slot.timeslot-passed {
    background-color: rgba(214, 119, 119, 0.95);
    border-color: #c66e6e; }
