.remove-header-icon-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer; }

.ReactTable.WebhooksTable.-floated-table .rt-tbody .rt-tr-group .rt-tr .rt-td.test-button-column {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .ReactTable.WebhooksTable.-floated-table .rt-tbody .rt-tr-group .rt-tr .rt-td.test-button-column .test-button {
    padding: 8px 35px;
    margin-left: 3px; }

.ReactTable.WebhooksTable.-floated-table .queued-cell {
  width: 30%;
  text-align: center; }
  @media (min-width: 1920px) {
    .ReactTable.WebhooksTable.-floated-table .queued-cell {
      width: 23%; } }

.ReactTable.WebhooksTable.-floated-table .send-support-tickets-cell {
  width: 82%;
  text-align: center; }
  @media (min-width: 1920px) {
    .ReactTable.WebhooksTable.-floated-table .send-support-tickets-cell {
      width: 60%; } }

.webhook-form-field {
  display: flex;
  flex-direction: column; }
  .webhook-form-field .ui.button.LocationDropdown {
    width: 90%;
    text-align: center; }
  .webhook-form-field .ui.checkbox {
    display: flex;
    align-items: center;
    height: 100%; }
  .webhook-form-field .ui.toggle.checkbox.webhook-modal-checkbox input:checked ~ label:before {
    background-color: #21ba45 !important; }

.WebhooksErrorLogTable {
  min-height: 140px; }
  .WebhooksErrorLogTable .ReactTable.-floated-table .rt-noData {
    top: 80px; }
