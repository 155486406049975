.ExactSearch {
  display: inline-flex;

  &__search-container {
    margin-bottom: 20px;
  }

  &__error {
    color: red;
    margin-bottom: 15px;
    justify-content: center;
    display: flex;
  }

  &__action-cell {
    display: flex;
    justify-content: center;
  }
}

.ExactSearch__modal {
  .ExactSearchTable-container {
    margin-top: 20px;
    min-height: 120px;

    .ReactTable .rt-tbody {
      overflow: hidden;
    }

    .rt-noData .Table__no-results {
      font-size: 1.4rem;
      margin-top: 120px;
      text-align: center;
    }
  }

  > .header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    cursor: pointer;
  }
}
