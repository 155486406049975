.full-width-input .copy-label {
  display: flex;
  align-items: center;

  i.copy.icon {
    cursor: pointer;

    &:hover {
      color: green;
    }
  }

  > div {
    word-break: break-all;
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    margin-left: 5px;
  }
}

.MCCConfiguration {
  &_header {
    .ui.button {
      margin-left: unset !important;
    }
  }

  &_container-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 8px;
    cursor: pointer;

    &-disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
