.Header {
  background-color: #46923d;
  border-bottom: 1px solid #367725;
  height: 60px;
  padding: 0.25em; }
  .Header .Header__column {
    height: 100%; }
    .Header .Header__column.-brand-column {
      padding-left: 0.75em !important; }
      .Header .Header__column.-brand-column .icon {
        color: #fff;
        cursor: pointer;
        font-size: 1.5em; }
      .Header .Header__column.-brand-column .Header__brand {
        color: #fff;
        display: inline-block;
        font-size: 1em;
        letter-spacing: 0.5px;
        padding-left: 0.5em;
        padding-top: 0.65rem;
        transform: translateY(-3px); }
        .Header .Header__column.-brand-column .Header__brand a {
          color: #fff; }
          .Header .Header__column.-brand-column .Header__brand a:hover {
            color: #e6e6e6; }
    .Header .Header__column.-search-column {
      padding-top: 0.55rem !important; }
      .Header .Header__column.-search-column .input {
        font-weight: 300;
        width: 100%; }
        .Header .Header__column.-search-column .input .search.icon {
          color: #fff; }
        .Header .Header__column.-search-column .input input {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 0;
          color: #fff;
          letter-spacing: 1px;
          transition: background-color ease 0.2s; }
          .Header .Header__column.-search-column .input input:focus {
            background-color: rgba(255, 255, 255, 0.2);
            border-color: transparent; }
        .Header .Header__column.-search-column .input ::-webkit-input-placeholder {
          /* Chrome */
          color: #f3f3f3;
          font-size: 0.9em;
          font-weight: 300; }
        .Header .Header__column.-search-column .input :-ms-input-placeholder {
          /* IE 10+ */
          color: #f3f3f3;
          font-size: 0.9em;
          font-weight: 300; }
        .Header .Header__column.-search-column .input ::-moz-placeholder {
          /* Firefox 19+ */
          color: #f3f3f3;
          font-size: 0.9em;
          font-weight: 300;
          opacity: 1; }
        .Header .Header__column.-search-column .input :-moz-placeholder {
          /* Firefox 4 - 18 */
          color: #f3f3f3;
          font-size: 0.9em;
          font-weight: 300;
          opacity: 1; }
    .Header .Header__column.-filter-column {
      padding-top: 0.7rem !important; }
      .Header .Header__column.-filter-column .ui.dropdown.icon {
        background: #236d1b;
        border-radius: 0;
        color: #fff;
        font-size: 0.9em;
        font-weight: 300;
        padding: 1em 1em 1em 3em !important;
        min-width: 155px; }
    .Header .Header__column.-user-menu-column {
      padding-top: 0.7rem !important; }
      .Header .Header__column.-user-menu-column .UserMenu__avatar.icon {
        float: right;
        font-size: 1.3em;
        margin-top: 0.8rem; }

.status-offline .Header {
  background-color: #757575 !important;
  border-bottom: 1px solid #757575 !important; }

.status-offline .DealerDropdown {
  background-color: rgba(255, 255, 255, 0.1) !important; }

.status-offline .LocationDropdown {
  background-color: rgba(255, 255, 255, 0.1) !important; }

.DealerDropdown {
  border: none !important; }
  .DealerDropdown input {
    color: #fff;
    margin-top: 2px;
    padding-left: 36px !important; }
  .DealerDropdown.active > .text:not(.default) {
    color: #a2a2a2 !important; }
