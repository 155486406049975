.reports-container {
  width: 98%;
  margin: 0% auto;
  height: 100%; }
  .reports-container .reports-header {
    width: 100%;
    padding-top: 14px;
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    height: 106px; }
    .reports-container .reports-header .reports-header-dropdown-container {
      margin-right: 50px; }
      .reports-container .reports-header .reports-header-dropdown-container .dropdown-container {
        display: inline-block;
        margin-left: 1em; }
  .reports-container .report-full-width {
    height: calc(100% - 121px);
    overflow-y: auto;
    margin-bottom: 15px; }
    .reports-container .report-full-width .recharts-tooltip-wrapper .recharts-default-tooltip {
      text-shadow: 1px 1px #aaa; }
  .reports-container .reports-heading {
    color: #34792c;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    text-align: left; }
  .reports-container .report-heading-full-width {
    text-align: center; }
  .reports-container .report-heading {
    color: #34792c;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    display: block;
    text-align: center; }
  .reports-container .no-data {
    text-align: center;
    margin-top: 20px; }
  .reports-container .report-half-width {
    display: flex;
    flex-wrap: wrap; }
  .reports-container .full-width-margin-auto {
    margin: auto !important; }
    .reports-container .full-width-margin-auto.simple-pie-chart .recharts-default-legend, .reports-container .full-width-margin-auto.total-pie-chart .recharts-default-legend {
      width: 395px; }
  .reports-container .report-box-half-width {
    margin-bottom: 7em;
    box-sizing: border-box; }
  .reports-container div.report-box-half-width:nth-child(even) {
    margin-left: 7em; }
  .reports-container .report-box-full-width {
    width: 100%;
    margin-bottom: 5em;
    box-sizing: border-box; }
    .reports-container .report-box-full-width:last-child {
      margin-bottom: 0; }
