.DeskCheckInQuestionModal {
  .input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: normal;

    svg {
      font-size: 16px;
    }
  }

  .input-container:last-child {
    position: relative;
    right: 3px;
  }
}
