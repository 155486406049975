.ui.grid {
  margin: 0; }
  .ui.grid.-full-height {
    height: 100vh; }

.ui.search.selection.dropdown > input.search {
  cursor: pointer; }

.ui.search.dropdown > .text {
  cursor: pointer; }

.ui.selection.dropdown:focus {
  border-color: #35792c; }

.ui.selection.dropdown .menu > .item {
  overflow: hidden;
  padding: 0.78571429rem 0.8em !important;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ui.input input {
  border-radius: 0 !important; }

.ui.button {
  border-radius: 0 !important;
  font-weight: 400;
  text-transform: capitalize; }

.ui.message {
  border-radius: 0 !important; }
  .ui.message .header {
    font-weight: 400 !important; }
