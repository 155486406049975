.QuestionElements .QuestionElement .ElementSolution {
  padding: 0 5px; }
  .QuestionElements .QuestionElement .ElementSolution.Scrollbar {
    height: calc(100vh - 260px);
    overflow-y: auto; }
  .QuestionElements .QuestionElement .ElementSolution .ListItem {
    margin-left: 0 !important;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    cursor: move; }
  .QuestionElements .QuestionElement .ElementSolution .NewLabel {
    width: 75%;
    margin-bottom: 25px; }
