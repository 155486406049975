.Questions .ListItem {
  border-radius: 5px;
  border: 1px solid lightgray;
  border-left: 4px solid gray;
  margin: 5px 0 5px 2px;
  width: 100%;
  padding: 10px;
  opacity: 0.5; }
  .Questions .ListItem * {
    pointer-events: none; }
  .Questions .ListItem .header {
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray; }
  .Questions .ListItem .description {
    margin-top: 10px; }
  .Questions .ListItem:hover, .Questions .ListItem.active {
    cursor: pointer;
    border-left: 4px solid red;
    background-color: white;
    opacity: 1; }
  .Questions .ListItem.hover {
    background-color: #eaeaea; }
  .Questions .ListItem .content {
    padding: 20px; }

.Questions .QuestionItem {
  background-color: white;
  padding: 10px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px; }
  .Questions .QuestionItem .content {
    width: 100%;
    line-height: 40px !important; }
  .Questions .QuestionItem.green {
    border-left: 4px solid green; }
  .Questions .QuestionItem.red {
    border-left: 4px solid red; }

.ui.form input:not([type]).drop-off-zone {
  text-align: center;
  padding: 3em;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15); }

.ui.form input:not([type]).drop-off-zone::-ms-clear {
  display: none; }

.template-checkbox {
  width: 50%; }
