.Login {
  background-color: #f7f7f7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 55%;
  height: 100vh;
  overflow-y: auto;
  width: 100%; }
  .Login .grid {
    margin: 0 auto !important;
    max-width: 1400px;
    height: 100%; }
  .Login .Login__overlay {
    background: #134e5e;
    background: -webkit-linear-gradient(to right, rgba(113, 178, 128, 0.819), #134e5e);
    background: linear-gradient(to right, rgba(113, 178, 128, 0.819), #134e5e);
    height: 100%;
    position: absolute;
    width: 100%; }
  .Login .Login__promo {
    margin-top: 15vh; }
    .Login .Login__promo .Login__promo__news {
      color: #fff; }
      .Login .Login__promo .Login__promo__news h1 {
        display: inline-block;
        font-size: 3.3em;
        font-weight: 700;
        width: auto; }
      .Login .Login__promo .Login__promo__news .Login__promo__news__feed {
        width: 95%; }
        .Login .Login__promo .Login__promo__news .Login__promo__news__feed article {
          font-size: 1.3em;
          line-height: 1.6em;
          margin-bottom: 1em; }
          .Login .Login__promo .Login__promo__news .Login__promo__news__feed article a {
            color: #fff;
            text-decoration: underline;
            font-weight: 700; }
          .Login .Login__promo .Login__promo__news .Login__promo__news__feed article .-read-more {
            display: inline-block;
            font-size: 0.8em;
            padding-right: 0.5em; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed article .-read-more:hover {
              cursor: pointer;
              color: #cacaca; }
          .Login .Login__promo .Login__promo__news .Login__promo__news__feed article.MainArticle {
            max-height: 175px; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed article.MainArticle img {
              max-height: 160px; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed article.MainArticle .-read-more {
              background: rgba(255, 255, 255, 0.3);
              color: white;
              padding: 0.15em 0.85em; }
              .Login .Login__promo .Login__promo__news .Login__promo__news__feed article.MainArticle .-read-more:hover {
                background-color: rgba(255, 255, 255, 0.5); }
          .Login .Login__promo .Login__promo__news .Login__promo__news__feed article .Login__promo__news__link {
            color: #fff;
            font-weight: 300;
            margin-top: 3vh; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed article .Login__promo__news__link a {
              border: 0.1px solid #fff;
              color: #fff;
              font-size: 0.8em;
              padding: 0.5em 1.5em;
              transition: background-color 0.2s ease; }
              .Login .Login__promo .Login__promo__news .Login__promo__news__feed article .Login__promo__news__link a:hover {
                background-color: rgba(255, 255, 255, 0.6);
                color: #000; }
        .Login .Login__promo .Login__promo__news .Login__promo__news__feed .Login__promo__news__feed__older {
          display: inline-block;
          margin-top: 2vh;
          width: 100%; }
          .Login .Login__promo .Login__promo__news .Login__promo__news__feed .Login__promo__news__feed__older article {
            background: #ffffff14;
            border: 1px solid #ffffff0d;
            float: left;
            font-size: 1.2em;
            height: 230px;
            margin-right: 1%;
            padding: 1em 0.5em;
            width: 32%; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed .Login__promo__news__feed__older article .Login__promo__news__link a {
              border: none;
              padding: 0; }
              .Login .Login__promo .Login__promo__news .Login__promo__news__feed .Login__promo__news__feed__older article .Login__promo__news__link a:hover {
                background-color: transparent;
                color: #eee; }
            .Login .Login__promo .Login__promo__news .Login__promo__news__feed .Login__promo__news__feed__older article .-content {
              display: inline-block;
              height: 130px; }
  .Login .Login__gate__container {
    background: #fff; }
    .Login .Login__gate__container .Login__gate__container__inner {
      margin: 1vh auto 1vh auto;
      width: 80%; }
      .Login .Login__gate__container .Login__gate__container__inner h1 {
        color: #646464;
        font-size: 1.8em;
        font-weight: 300;
        text-align: center; }
        .Login .Login__gate__container .Login__gate__container__inner h1 span {
          color: #22ba45;
          font-weight: 500; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions h2 {
        color: #4b4b4b;
        font-weight: 300;
        font-size: 1.5em; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .ui.form {
        margin-top: 1vh; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .ui.divider {
        color: #707070;
        font-weight: 400; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .LoginForm .google-login {
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 3rem;
        background-size: 1.5em;
        border: 1px solid #cacaca;
        color: #000; }
        .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .LoginForm .google-login:hover {
          background-color: #fcfcfc; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .LoginForm .ui.message {
        text-align: center; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .LoginForm .qr-login {
        background-color: #2348b0;
        height: 43px; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions .LoginForm .LightGray {
        color: #ababab; }
      .Login .Login__gate__container .Login__gate__container__inner .Login__gate__container__inner__actions input:focus {
        border-color: #7ab47a; }
  .Login .Login__info {
    position: absolute;
    bottom: 20px;
    left: 0;
    color: #c7d5d5;
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 1px;
    padding-top: 5vh;
    text-align: center;
    width: 100%; }
    .Login .Login__info a:hover {
      color: #eee; }
  .Login .message p {
    font-size: 0.8em;
    letter-spacing: 1px;
    margin-top: 7px !important;
    text-transform: uppercase; }
  .Login .QRCode_Container {
    text-align: center;
    padding-top: 20px; }
    .Login .QRCode_Container .TextRed {
      color: #e03f3f; }

.ResetPassword .reset-indicator {
  font-size: 1.75em;
  position: absolute;
  right: 5px;
  top: 0;
  transform: translateY(13px); }
  .ResetPassword .reset-indicator.good {
    color: green; }
  .ResetPassword .reset-indicator.bad {
    color: #f54545; }
