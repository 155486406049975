.dealer-admin-configuration-container {
  position: relative; }
  .dealer-admin-configuration-container .dealer-admin-configuration-alert-container > div {
    position: absolute; }

.content section {
  border: 1px solid #eee;
  border-radius: 3px; }
  .content section p {
    border-bottom: 1px solid #eee;
    color: #4f4949;
    padding: 1em; }
    .content section p:last-of-type {
      border-bottom: none; }
    .content section p strong {
      font-size: 1.2em;
      margin-left: 1em; }

.highlight-red-row {
  color: red; }

.DealersTable {
  font-size: 1em; }
  .DealersTable .invoicing-dealer-icon-box div {
    display: inline-block; }
  .DealersTable .invoicing-dealer-icon-box div:nth-child(2) {
    margin-right: 6px; }
  .DealersTable .invoicing-dealer-icon-box i {
    margin: 0px 4px 0px 0px !important; }
  .DealersTable .invoicing-location-icon-box {
    width: 20%;
    text-align: center; }
  .DealersTable .rt-td .cloud-upload-icon {
    margin: 0 16px; }
  .DealersTable .rt-thead .rt-th {
    border: none; }
  .DealersTable .exact-invoicing-dealer-cell {
    position: relative;
    right: 13px; }
  .DealersTable .bad-imports-heder {
    margin-right: 20px; }
    .DealersTable .bad-imports-heder div {
      margin: 0 auto; }
  .DealersTable .bad-imports-cell {
    text-align: center;
    margin: 0 auto;
    margin-right: 30px; }

.titleSelector h2 button {
  vertical-align: bottom; }
  .titleSelector h2 button:first-child {
    margin-left: 10px; }

.ui .segment .ui .form .full-width-input {
  max-width: 100%; }

.ui.disabled.input.daily-check-link-input-field {
  max-width: 95%; }

.Dealers .SubHeader_content_filters > div.column.filters {
  display: flex;
  width: 100%; }
  .Dealers .SubHeader_content_filters > div.column.filters button {
    margin-left: auto;
    margin-right: 15px; }
  .Dealers .SubHeader_content_filters > div.column.filters .ui.dropdown {
    height: 100%; }
    .Dealers .SubHeader_content_filters > div.column.filters .ui.dropdown div.text {
      display: flex;
      font-size: 1.05em;
      vertical-align: middle;
      position: relative;
      top: 1px; }

.ui.header .content.DealerInvoicingSettings__header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .ui.header .content.DealerInvoicingSettings__header > div {
    display: flex;
    align-items: center; }
  .ui.header .content.DealerInvoicingSettings__header div > svg.fa-file-invoice-dollar {
    font-size: 1.5em;
    margin-right: 10px; }
  .ui.header .content.DealerInvoicingSettings__header .header-checkbox {
    display: flex;
    align-items: center;
    font-weight: 400; }
    .ui.header .content.DealerInvoicingSettings__header .header-checkbox label {
      margin-right: 10px; }
