.ROBDetail {
  button.ui.green.basic.fluid.button.ROBButton {
    font-size: 16px;
    margin-bottom: 3px;
  }
}

.ui.modal.transition.visible.active.ROBModal {
  width: 1230px;
  margin: 10px;
  padding: 0px;
  box-sizing: border-box;

  .content {
    padding: 0px 10px;
  }

  .ui.modal .scrolling.content {
    height: auto;
  }

  .column {
    padding: 0px 10px 0px 10px;
  }

  .modal-header.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
      font-size: 18px;
    }
  }

  .segment-title {
    font-size: 14px;
    font-weight: 900;
    margin: 10px 0px;
    border-bottom: 1px solid #e2e2e3;
    padding-bottom: 10px;
  }

  .section-title {
    font-size: 14px;
    font-weight: 900;
    font-style: italic;
    margin: 10px 0px;
  }

  .section-title:first-child {
    font-style: normal;
  }

  .section-title-separator {
    border-top: 1px solid #e2e2e3;
    padding-top: 10px;
  }

  .rob-modal-section {
    .data-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 3px;
      color: #000000cc;
      font-size: 14px;

      div {
        word-wrap: break-word;
      }

      div:first-child {
        width: 74%;
        margin-right: 1%;
        font-weight: 600;
      }

      div:nth-child(2) {
        width: 25%;
        text-align: left;
      }
    }

    .data-row-long-text {
      display: block;

      div {
        display: block;
        width: 100%;
      }

      div:first-child,
      div:nth-child(2) {
        width: 100%;
        margin-bottom: 5px;
      }

      div:first-child {
        margin-bottom: 0px;
      }

      div:nth-child(2) {
        margin-bottom: 5px;
      }
    }
  }

  .ui.segment {
    background-color: #f9fafb !important;
    margin-bottom: 33px;
    padding: 12px;
    box-sizing: border-box;
  }

  .ui.segment:last-child {
    margin-bottom: 0px;
  }
}
