.status-filter {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid;

  &.disabled {
    border-color: #767676 !important;

    .status-filter-icon {
      background-color: #767676 !important;
      color: #e2e2e3;
    }

    .status-filter-count {
      background-color: #e2e2e3;
    }
  }

  &.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.3rem;
    padding: 5px 6px;
    height: 100%;
    width: 36px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;

    i.material-icons {
      font-size: 20px;
    }

    .backorder-icon span {
      font-size: 16px;
      line-height: 16px;
      vertical-align: top;
    }
  }

  &-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    width: 29px;
    height: 100%;
    font-size: 13px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.status-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
