.customer_sys {
  margin: 0; }

.dealers-tab .dropdown {
  margin-top: 4px; }

.Users .ReactTable.-floated-table.custom-users .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
  padding: 0px; }

.Users .SubHeader_content_filters .row:not(:last-child) {
  padding-bottom: 0.5rem; }

.Users .SubHeader_content_filters .react-datepicker-wrapper {
  width: 100%; }

.Users .SubHeader_content_filters .react-datepicker__input-container {
  width: 100%; }
  .Users .SubHeader_content_filters .react-datepicker__input-container .react-datepicker__close-icon {
    padding: 0 12px 0 0; }
    .Users .SubHeader_content_filters .react-datepicker__input-container .react-datepicker__close-icon::after {
      color: #665e5e;
      font-size: 18px;
      background-color: transparent; }
  .Users .SubHeader_content_filters .react-datepicker__input-container input[type="text"] {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.1); }
    .Users .SubHeader_content_filters .react-datepicker__input-container input[type="text"]::placeholder {
      color: #bfbfbf;
      font-size: 13px;
      font-weight: 500; }
    .Users .SubHeader_content_filters .react-datepicker__input-container input[type="text"]:hover {
      box-shadow: none;
      border-color: rgba(34, 36, 38, 0.35); }
    .Users .SubHeader_content_filters .react-datepicker__input-container input[type="text"]:focus {
      border-color: #96c8da;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15); }

@media only screen and (max-width: 1352px) {
  .Users .SubHeader_content_filters .SubHeader_content_title_container {
    top: 5px; }
    .Users .SubHeader_content_filters .SubHeader_content_title_container .SubHeader_content_title {
      font-size: 23px; }
  .Users .SubHeader_content_filters .pair-users-button {
    padding: 0rem 1rem; } }

.Users .users-table-count {
  width: 50%;
  height: 100%;
  font-size: 1.3em;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.Users .pagination-bottom .-pagination .-next button:enabled {
  background-color: #46923d;
  color: white; }
  .Users .pagination-bottom .-pagination .-next button:enabled:hover {
    background-color: #377730;
    color: white; }

.Users .pagination-bottom .-pagination .-previous button:enabled {
  background-color: #46923d;
  color: white; }
  .Users .pagination-bottom .-pagination .-previous button:enabled:hover {
    background-color: #377730;
    color: white; }

.Users .pair-users-button {
  pointer-events: none; }

.Users .paired-icon {
  position: relative;
  left: 8px; }
  .Users .paired-icon i.icon {
    font-size: 16px; }

.PairUsersModal_row {
  display: inline-flex;
  width: 100%;
  column-gap: 15px;
  margin-bottom: 18px; }
  .PairUsersModal_row-dropdowns {
    display: inline-flex;
    flex: 70%;
    column-gap: 15px; }

.PairUsersModal_close-btn {
  float: left; }

.PairUsersModal_save-btn i.icon {
  margin-left: 4px !important; }

.PairUsersModal .green.checkmark.icon {
  position: relative;
  align-self: center;
  bottom: 6px; }

.pair-users-dropdown-container {
  float: right; }
  .pair-users-dropdown-container .ui.dropdown.button .menu .item.selected {
    background-color: initial; }
  .pair-users-dropdown-container .ui.dropdown.button .menu .item:hover {
    background-color: rgba(0, 0, 0, 0.05); }

.ui.page.modals.dimmer.transition.visible.active .user-detail-header.header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ui.page.modals.dimmer.transition.visible.active .user-detail-header div:first-child {
  margin-right: 25px; }
