.PonRecallDetail {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    font-size: 18px;
  }

  .PonRecallDetail__content {
    .row > .column {
      padding-left: 0 !important;
      margin: 0;

      span {
        margin-left: 6px;
      }
    }

    .PONCampaign__separator {
      border-bottom: 1px solid #eee;
      margin: 12px 0 0;
      width: 100%;
    }
  }
}
.button.ui.basic.pon-button {
  margin-bottom: 4px !important;
  font-size: 14px;

  &.-red-border {
    background: 0 0 !important;
    box-shadow: 0 0 0 1px #d01919 inset !important;
    color: #d01919 !important;
  }
}
