.EditChecklist .Checkbox {
  padding: 5px;
  background-color: #f3f2f2;
  border-radius: 10px;
  margin-right: 5px; }

.EditChecklist .Checkbox:first-child {
  margin-left: 5px; }

.EditChecklist .QuestionGroup {
  background-color: #e8e8e8 !important; }

.EditChecklist .QuestionGroup input {
  border: none; }

.EditChecklist .ui.disabled.dropdown.checklist-attached-dropdown,
.EditChecklist .ui.dropdown.checklist-attached-dropdown .menu > .disabled.item {
  opacity: 1; }

.ChecklistQuestions .ChecklistQuestionsHeader {
  display: flex;
  align-items: center; }

.ChecklistQuestions .HeaderActions {
  display: flex;
  font-size: 1rem;
  flex: 1; }
  .ChecklistQuestions .HeaderActions .ui.input {
    flex: 1;
    margin-right: 10px;
    margin-left: 40px; }
  .ChecklistQuestions .HeaderActions .ui.selection.dropdown {
    bottom: 1px;
    margin-right: 10px; }

.ChecklistQuestions .question {
  cursor: pointer;
  background-color: white;
  padding: 10px !important;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  line-height: 40px !important; }
  .ChecklistQuestions .question.green {
    border-left: 4px solid green; }
  .ChecklistQuestions .question.red {
    border-left: 4px solid red; }
  .ChecklistQuestions .question span {
    color: white;
    text-shadow: 2px 2px #000000;
    margin-left: 5px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 12px;
    padding: 1px 7px 3px 5px; }

.ChecklistQuestionsSortableHelper {
  z-index: 1000; }
