.ReplacementOrderTable.ReactTable {
  &.-floated-table .rt-thead.-header {
    width: 100%;

    .rt-th.-text-center-header {
      justify-content: center;
    }
  }

  .rt-table {
    overflow: visible;
  }

  .rt-tbody {
    overflow: visible;
    width: 100% !important;
    min-height: 100px;
  }

  .rt-tr {
    height: 100%;
    align-items: center;
    background-color: #fff;

    .rt-td {
      overflow: visible;

      .replacement-tyre-ean {
        display: inline-block;
        width: 50%;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }
  }

  .high-stock {
    border: 1px solid #dededf;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .low-stock {
    background-color: #fdf6f6;
    border: 1px solid #d9b6b5;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #767676;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.pagination-bottom {
  .-pagination {
    .-next {
      button:enabled {
        &:hover {
          background-color: #377730;
          color: white;
        }
        background-color: #46923d;
        color: white;
      }
    }
    .-previous {
      button:enabled {
        &:hover {
          background-color: #377730;
          color: white;
        }
        background-color: #46923d;
        color: white;
      }
    }
  }
}

.ui.fullscreen.modal.transition.visible.active.tyre-team-replacement-modal {
  top: auto !important;
  right: 0px !important;
  width: 98% !important;
  margin: auto !important;

  i.pencil.square.icon.render-refs-input-icon::before {
    color: #600bff;
    font-size: 25px;
  }

  .ui.icon.input > i.render-refs-input-icon.icon {
    opacity: 1;
  }
}
