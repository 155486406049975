.UserMenu {
  display: inline-block;
  float: right; }
  .UserMenu .UserMenu__actions,
  .UserMenu .pseudo-zoho-icon {
    color: #fff;
    display: inline-block;
    font-size: 1.3em;
    margin: 0.6em 0.5em 0 0; }
    .UserMenu .UserMenu__actions .icon,
    .UserMenu .pseudo-zoho-icon .icon {
      cursor: pointer; }
    .UserMenu .UserMenu__actions > div,
    .UserMenu .pseudo-zoho-icon > div {
      display: inline-block; }
    .UserMenu .UserMenu__actions.manufacturer-link,
    .UserMenu .pseudo-zoho-icon.manufacturer-link {
      position: relative; }
      .UserMenu .UserMenu__actions.manufacturer-link .label,
      .UserMenu .pseudo-zoho-icon.manufacturer-link .label {
        font-size: 9px;
        top: 60%;
        left: 110%; }
  .UserMenu .pseudo-zoho-icon {
    cursor: pointer; }
  .UserMenu img.UserMenu__Profile-pic {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #45913d;
    display: inline-block !important;
    height: 40px;
    transform: translateY(-3px);
    width: 40px;
    -webkit-transition: border 500ms ease-out;
    -moz-transition: border 500ms ease-out;
    -o-transition: border 500ms ease-out;
    transition: border 500ms ease-out; }
    .UserMenu img.UserMenu__Profile-pic:hover {
      cursor: pointer;
      border-color: #b0d0ac; }
  .UserMenu .icon:hover {
    opacity: 0.8; }
  .UserMenu .manufacturer-icon-badge.ui.label {
    font-size: 9px;
    position: static;
    margin-left: 0; }
  .UserMenu .manufacturer-icon {
    margin-right: 0 !important; }

.UserMenu__popup.ui.popup {
  padding: 0; }
  .UserMenu__popup.ui.popup ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .UserMenu__popup.ui.popup ul li {
      border-bottom: 1px solid #eee;
      padding: 0.5em;
      width: 100px; }
      .UserMenu__popup.ui.popup ul li:hover {
        background-color: #f7f7f7;
        cursor: pointer; }
    .UserMenu__popup.ui.popup ul a {
      color: #212121;
      display: inline-block; }

.UserMenu__popup .UserMenu__avatar {
  font-size: 1.8em; }
  .UserMenu__popup .UserMenu__avatar:hover {
    cursor: pointer; }
