.StatusFilter {
  display: flex;
  height: 100%; }
  .StatusFilter .StatusFilter_status {
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    flex: 1;
    float: left;
    color: #000;
    height: 100%;
    margin-right: 5px;
    padding-top: 1em; }
    .StatusFilter .StatusFilter_status:last-of-type {
      margin-right: 0; }
    .StatusFilter .StatusFilter_status i.material-icons {
      color: #000;
      font-size: 1.3em;
      transform: translateY(-2px); }
    .StatusFilter .StatusFilter_status.-is-active-false {
      opacity: 0.3; }
      .StatusFilter .StatusFilter_status.-is-active-false:hover {
        opacity: 0.3; }
    .StatusFilter .StatusFilter_status:hover {
      cursor: pointer;
      opacity: 0.7; }
    .StatusFilter .StatusFilter_status .back-order-status-icon {
      font-size: 13px;
      font-weight: 600;
      position: relative;
      bottom: 4.5px; }
