.incomplete-tires-modal .tire-modal-checkbox {
  padding: 5px;
  background-color: #f3f2f2;
  border-radius: 10px;
  padding-right: 30px; }

.incomplete-tires-modal .inner-table-wrapper {
  display: block;
  max-width: 100%;
  overflow-x: hidden; }
  .incomplete-tires-modal .inner-table-wrapper .confirm-button-wrapper {
    display: flex;
    justify-content: flex-end; }
    .incomplete-tires-modal .inner-table-wrapper .confirm-button-wrapper .confirm-button {
      cursor: pointer; }
  .incomplete-tires-modal .inner-table-wrapper .pagination-bottom .-pagination .-next button:enabled {
    background-color: #46923d;
    color: white; }
    .incomplete-tires-modal .inner-table-wrapper .pagination-bottom .-pagination .-next button:enabled:hover {
      background-color: #377730;
      color: white; }
  .incomplete-tires-modal .inner-table-wrapper .pagination-bottom .-pagination .-previous button:enabled {
    background-color: #46923d;
    color: white; }
    .incomplete-tires-modal .inner-table-wrapper .pagination-bottom .-pagination .-previous button:enabled:hover {
      background-color: #377730;
      color: white; }
  .incomplete-tires-modal .inner-table-wrapper .ReactTable .rt-thead.-header {
    width: 100%; }
  .incomplete-tires-modal .inner-table-wrapper .ReactTable .rt-thead .rt-th {
    padding: 8px 0; }
  .incomplete-tires-modal .inner-table-wrapper .ReactTable .rt-tbody {
    width: 100%; }
    .incomplete-tires-modal .inner-table-wrapper .ReactTable .rt-tbody .rt-tr-group:hover {
      cursor: default; }

.confirm-replace-tire .outer-content-box {
  width: auto; }

.confirm-replace-tire .tire-reference-group .inner-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem; }
  .confirm-replace-tire .tire-reference-group .inner-content-box.replace-tire {
    margin-top: 1.5rem; }
  .confirm-replace-tire .tire-reference-group .inner-content-box p {
    font-weight: 700;
    margin-bottom: 0.5rem; }
  .confirm-replace-tire .tire-reference-group .inner-content-box .tire-reference-row,
  .confirm-replace-tire .tire-reference-group .inner-content-box .tire-reference-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 1px;
    border: 1px solid #e4e4e4;
    color: #0c0c0c;
    font-size: 0.9em;
    height: 40px;
    max-height: 40px;
    margin-bottom: 1.5px;
    padding: 0.3rem;
    width: 100%; }
  .confirm-replace-tire .tire-reference-group .inner-content-box .tire-reference-header {
    border: 0;
    padding: 0;
    height: auto;
    margin: 6px 0; }
    .confirm-replace-tire .tire-reference-group .inner-content-box .tire-reference-header label {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.87); }
  .confirm-replace-tire .tire-reference-group .inner-content-box span,
  .confirm-replace-tire .tire-reference-group .inner-content-box label {
    color: #584a4a;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-20,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-20 {
      width: 20px;
      max-width: 20px;
      flex: 20 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-30,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-30 {
      width: 30px;
      max-width: 30px;
      flex: 30 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-40,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-40 {
      width: 40px;
      max-width: 40px;
      flex: 40 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-50,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-50 {
      width: 50px;
      max-width: 50px;
      flex: 50 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-60,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-60 {
      width: 60px;
      max-width: 60px;
      flex: 60 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-70,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-70 {
      width: 70px;
      max-width: 70px;
      flex: 70 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-80,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-80 {
      width: 80px;
      max-width: 80px;
      flex: 80 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-90,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-90 {
      width: 90px;
      max-width: 90px;
      flex: 90 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span.row-width-100,
    .confirm-replace-tire .tire-reference-group .inner-content-box label.row-width-100 {
      width: 100px;
      max-width: 100px;
      flex: 100 0 auto; }
    .confirm-replace-tire .tire-reference-group .inner-content-box span:not(:last-of-type),
    .confirm-replace-tire .tire-reference-group .inner-content-box label:not(:last-of-type) {
      margin-right: 0.5rem; }

.SubHeader_content_total_tires {
  font-size: 1.2em;
  font-weight: 600;
  color: #665e5e; }

.TyreTable .pagination-bottom .-pagination .-next button:enabled {
  background-color: #46923d;
  color: white; }
  .TyreTable .pagination-bottom .-pagination .-next button:enabled:hover {
    background-color: #377730;
    color: white; }

.TyreTable .pagination-bottom .-pagination .-previous button:enabled {
  background-color: #46923d;
  color: white; }
  .TyreTable .pagination-bottom .-pagination .-previous button:enabled:hover {
    background-color: #377730;
    color: white; }
