.AppointmentSnoozedItems .Loader-Placeholder {
  padding: 1.5em;
  color: #9f9f9f;
  font-size: 1.3em;
  font-weight: 700;
  margin-top: 2em;
  padding: 50px 0;
  text-align: center; }
  .AppointmentSnoozedItems .Loader-Placeholder section {
    margin-top: 25px; }
  .AppointmentSnoozedItems .Loader-Placeholder .icon {
    font-size: 3em;
    margin-bottom: 15px; }

.AppointmentSnoozedItems .appointment-snoozed-container {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  margin-bottom: 5px; }

.AppointmentSnoozedItems .appointment-snoozed-title {
  background-color: #2285d0;
  color: #fff;
  padding: 0.35em 1em; }
  .AppointmentSnoozedItems .appointment-snoozed-title h1 {
    display: inline-block;
    font-size: 1.25em;
    margin-bottom: 0;
    width: auto;
    text-transform: uppercase;
    cursor: pointer; }
