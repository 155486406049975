.ChecklistDeleteLabel {
  margin: 0;
  transform: translateY(-2px); }
  .ChecklistDeleteLabel .icon {
    margin: 0 !important;
    opacity: 1 !important; }

form.checklist-settings-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%; }
