.TotalItems {
  background-color: #b3ffe6;
  padding: 0.5em;
  text-align: right;
  margin-top: 5px; }
  .TotalItems h5 {
    display: inline;
    font-style: italic;
    margin-right: 0; }

.AppointmentDetail {
  height: 100%; }
  .AppointmentDetail #_svcbox_btt {
    border-radius: 0.28571429rem;
    border: 1px solid #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    display: inline-block;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    margin: 0 0.25em 0 0;
    min-height: 1em;
    opacity: 0;
    outline: 0;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    transform: scale(0.5);
    transition: all 0.1s ease-in-out;
    vertical-align: baseline;
    background: transparent;
    border-radius: 0;
    border: 1px solid #cccccc;
    font-size: 15px;
    font-weight: 400;
    height: 19px;
    line-height: 19px;
    overflow: hidden;
    padding: 6px 0;
    text-transform: uppercase;
    width: 95%; }
  .AppointmentDetail .anim {
    opacity: 1 !important;
    transform: scale(1) !important; }
  .AppointmentDetail .AppointmentDetail__side {
    background-color: #fafafa;
    border-right: 1px solid #eee;
    display: inline-block;
    float: left;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 12%;
    position: relative; }
    .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .internal-icon {
      margin-right: 4.5px;
      height: 13px !important;
      width: 14.1562px !important;
      margin-bottom: 0.2px;
      padding: 0.1em;
      line-height: 14.4px;
      font-size: 12px;
      border-radius: 6px;
      background-color: red;
      border-color: red;
      color: white; }
    .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .react-datepicker-popper {
      z-index: 9999999; }
    .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .appointment-date-section {
      padding-right: 0px; }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .appointment-date-section div {
        font-weight: bold; }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .appointment-date-section span.InlineInput.-detail-value {
        display: block;
        width: 100%; }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__list .appointment-date-section .InlineInput__valueinput {
        display: flex;
        width: 100%; }
    .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px); }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user {
        display: inline-block; }
        .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user img,
        .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user span {
          background-color: #fff;
          border-radius: 50%;
          display: inline-block;
          height: 40px;
          width: 40px;
          margin-right: 5px;
          margin-bottom: 5px; }
          .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user img.online,
          .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user span.online {
            border: 2px solid #45913d; }
          .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user img.away,
          .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user span.away {
            border: 2px solid #b4c7b2; }
        .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__online_users .AppointmentDetail__side__online_user span {
          color: black;
          font-size: 22px;
          text-align: center;
          padding-top: 7px; }
    .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__picture {
      background-color: #eaeaea;
      text-align: center; }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__picture img {
        width: 100%; }
      .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__picture .AppointmentDetail__side__picture__empty {
        padding-bottom: 1em; }
        .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__picture .AppointmentDetail__side__picture__empty .icon {
          margin-top: 0.75em;
          font-size: 3em;
          color: #979797; }
        .AppointmentDetail .AppointmentDetail__side .AppointmentDetail__side__picture .AppointmentDetail__side__picture__empty p {
          color: #979797;
          font-size: 1em;
          margin: 0.5em 0; }
    .AppointmentDetail .AppointmentDetail__side ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .AppointmentDetail .AppointmentDetail__side ul li {
        background: #fff;
        border: 1px solid #eee;
        border-bottom: none;
        border-right: none;
        padding: 0.65em 0.75em; }
        .AppointmentDetail .AppointmentDetail__side ul li:last-of-type {
          border-bottom: 1px solid #eee; }
        .AppointmentDetail .AppointmentDetail__side ul li span {
          display: block;
          text-transform: capitalize;
          font-size: 0.85em; }
          .AppointmentDetail .AppointmentDetail__side ul li span.-detail-value, .AppointmentDetail .AppointmentDetail__side ul li span.-apk {
            font-size: 1em;
            font-weight: 600;
            line-height: 1.2em;
            margin-top: 0.5em;
            width: 90%;
            word-break: break-word; }
            .AppointmentDetail .AppointmentDetail__side ul li span.-detail-value.-reg-nr, .AppointmentDetail .AppointmentDetail__side ul li span.-apk.-reg-nr {
              display: inline-flex;
              gap: 0.25rem;
              font-size: 1em; }
              .AppointmentDetail .AppointmentDetail__side ul li span.-detail-value.-reg-nr .icon, .AppointmentDetail .AppointmentDetail__side ul li span.-apk.-reg-nr .icon {
                margin-right: 2px; }
              .AppointmentDetail .AppointmentDetail__side ul li span.-detail-value.-reg-nr span.reg-nr-clickable, .AppointmentDetail .AppointmentDetail__side ul li span.-apk.-reg-nr span.reg-nr-clickable {
                font-size: 1em; }
                .AppointmentDetail .AppointmentDetail__side ul li span.-detail-value.-reg-nr span.reg-nr-clickable:hover, .AppointmentDetail .AppointmentDetail__side ul li span.-apk.-reg-nr span.reg-nr-clickable:hover {
                  cursor: pointer;
                  color: green; }
          .AppointmentDetail .AppointmentDetail__side ul li span .input input {
            padding: 0; }
          .AppointmentDetail .AppointmentDetail__side ul li span .input button {
            padding: 5px 0 5px 10px !important; }
        .AppointmentDetail .AppointmentDetail__side ul li.-customer-info .-detail-value {
          text-transform: none; }
        .AppointmentDetail .AppointmentDetail__side ul li.-customer-info:hover {
          color: green;
          cursor: pointer; }
  .AppointmentDetail .AppointmentDetail__content {
    display: inline-block;
    float: left;
    height: 100%;
    overflow-y: auto;
    width: 88%; }
    .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar {
      border-bottom: 1px solid #eee;
      float: left;
      padding: 0.35em 1em 0 1em;
      position: fixed;
      width: 88%;
      height: 3.5em;
      background-color: #fff;
      z-index: 999999; }
      .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .-close {
        font-size: 1.2em;
        position: absolute;
        right: 2em;
        text-transform: uppercase;
        top: 1.5em; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .-close:hover {
          color: #8e8e8e;
          cursor: pointer; }
      .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps {
        display: inline-block;
        float: left;
        transform: translateY(3px); }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps {
          border-radius: 0; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step {
            cursor: pointer;
            font-size: 0.65rem;
            padding: 0.5em 1.25em; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step:hover {
              background-color: #f7f7f7; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-active {
              background: #67d32f;
              color: #fff; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-active .content .title {
                color: #fff; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-active:after {
                background-color: #67d32f; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-on {
              background: #67d32f;
              color: #fff; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-on:after {
                background-color: #67d32f; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step.-active-status-off {
              cursor: pointer; }
            @media (min-width: 1712px) {
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step div.content {
                width: 3.5vw; } }
            @media (min-width: 1920px) {
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step div.content {
                width: 4vw;
                max-width: 4.5vw; } }
            @media (min-width: 2160px) {
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step div.content {
                width: 5.5vw;
                max-width: 6vw; } }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__steps .ui.steps .step div.content div.title {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
      .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions {
        display: flex;
        float: right;
        margin-top: 0.25em; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions > .button,
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions > span,
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions > div {
          float: left; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .icon {
          margin: 0 !important; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .download-pdf {
          margin-right: 0.25em !important; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .buttons {
          border-radius: 0; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .buttons .button {
            font-size: 1rem; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .button {
          font-size: 1.1rem;
          height: auto;
          padding: 0.55em 1.1em; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .button.-info-status.-active-status-active {
            background: #3ab4c6 !important;
            color: #fff !important; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .button.-info-status.-active-status-active:after {
              background: #0e5e83; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .button.-info-status.status-disabled {
            opacity: 0.5;
            pointer-events: none; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .KioskLabelComposite {
          margin-right: 10px;
          max-width: 60px;
          padding-right: 0.95em;
          padding-left: 0.95em; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .KioskLabelComposite .fa-tag {
            font-size: 13px;
            position: relative;
            left: -2px; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .KioskLabelComposite span {
            font-size: 14px;
            position: relative;
            left: 2px; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons,
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons {
          margin-right: 5px; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons:hover,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons:hover {
            border-color: rgba(34, 36, 38, 0.35); }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons:hover .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons:hover .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons:hover .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons:hover .star-color-dropdown {
              border-color: rgba(34, 36, 38, 0.35); }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green {
            border-color: #21ba45; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green .star-color-dropdown {
              border-color: #21ba45; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green:hover {
              border-color: #16ab39; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.green:hover .star-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.green:hover .star-color-dropdown {
                border-color: #16ab39; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red {
            border-color: #db2828; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red .star-color-dropdown {
              border-color: #db2828; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red:hover {
              border-color: #d01919; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.red:hover .star-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.red:hover .star-color-dropdown {
                border-color: #d01919; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue {
            border-color: #2185d0; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue .star-color-dropdown {
              border-color: #2185d0; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue:hover {
              border-color: #1678c2; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.blue:hover .star-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.blue:hover .star-color-dropdown {
                border-color: #1678c2; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange {
            border-color: #f2711c; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange .star-color-dropdown {
              border-color: #f2711c; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange:hover {
              border-color: #f2711c; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.orange:hover .star-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.orange:hover .star-color-dropdown {
                border-color: #f2711c; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow {
            border-color: #fbbd08; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow .star-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow .shop-color-dropdown,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow .star-color-dropdown {
              border-color: #fbbd08; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow:hover {
              border-color: #fbbd08; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons.yellow:hover .star-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow:hover .shop-color-dropdown,
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons.yellow:hover .star-color-dropdown {
                border-color: #fbbd08; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons button.ui.button,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons button.ui.button {
            border: 0;
            outline: none;
            box-shadow: none !important; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .shop-color-dropdown,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .star-color-dropdown,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .shop-color-dropdown,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .star-color-dropdown {
            padding: 0.55em;
            outline: none;
            box-shadow: none !important;
            border: 0;
            border-left: 1px solid;
            border-color: rgba(34, 36, 38, 0.15); }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .shop-color-dropdown:hover, .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .shop-color-dropdown:focus,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .star-color-dropdown:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .star-color-dropdown:focus,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .shop-color-dropdown:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .shop-color-dropdown:focus,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .star-color-dropdown:hover,
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .star-color-dropdown:focus {
              box-shadow: none !important; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .shop-color-dropdown > .text,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .shop-color-dropdown > .text {
            display: none; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .shop-color-buttons .shop-color-dropdown .menu .item span.text,
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .star-color-buttons .shop-color-dropdown .menu .item span.text {
            margin-left: 10px;
            text-transform: capitalize; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons {
          margin-right: 10px;
          border: 1px solid #4283ca; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons:hover {
            border-color: #2873ca; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons button.ui.button {
            border: 0;
            outline: none;
            box-shadow: none !important;
            padding-left: 1.25rem;
            padding-right: 1.25rem; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown {
            padding: 0.55em;
            outline: none;
            box-shadow: none !important;
            border: 0;
            border-left: 1px solid;
            border-color: #4283ca; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown i.dropdown.icon {
              color: #4283ca; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown:hover, .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown:focus {
              box-shadow: none !important; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item {
              display: flex;
              border-bottom: 1px solid #dededf;
              padding: 0.75rem 3rem 0.75rem 1.5rem !important; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container {
                display: inline-flex;
                width: 28px;
                height: 21px;
                margin-right: 3px;
                align-items: center; }
                .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container.square-icon {
                  display: inline-flex;
                  border-radius: 4px;
                  right: 8px;
                  position: relative;
                  justify-content: center;
                  align-items: center;
                  align-content: center; }
                  .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container.square-icon.info-icon {
                    background-color: #2185d0; }
                    .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container.square-icon.info-icon svg {
                      color: #fff;
                      font-size: 11px; }
                  .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container.square-icon.backorder-icon {
                    background-color: purple; }
                    .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item .icon-container.square-icon.backorder-icon span {
                      font-size: 11px;
                      color: #fff; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item.selected:not(:hover) {
                background-color: transparent; }
              .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .AppointmentNoteButtons .AppointmentNoteDropdown .visible.menu .item span.text {
                display: inline-flex;
                align-items: center; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar .AppointmentDetail__content__topbar__actions .appointment-cancel-button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          padding: 0.25em 1.1em; }
      .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar button.ui.large.basic.button.KeyLabelPrintCompositeIcon {
        display: inline-block;
        margin-right: 10px;
        padding: 0.55em 0.5em 0.55em 1em; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar button.ui.large.basic.button.KeyLabelPrintCompositeIcon svg {
          color: #767676;
          font-size: 12px; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar button.ui.large.basic.button.KeyLabelPrintCompositeIcon svg:first-child {
            font-size: 15px; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__topbar button.ui.large.basic.button.KeyLabelPrintCompositeIcon svg:nth-child(2) {
          transform: translate(-5px, 5px); }
    .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner {
      min-height: 100%;
      overflow-y: auto;
      padding: 3.75em 1em 2em 1em; }
      .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline {
        margin-top: 0;
        z-index: 1000; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.segments {
          border: none;
          box-shadow: none;
          color: #494949;
          margin-bottom: 0.5em; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.segments .segment {
            border: none;
            margin-right: 1px;
            padding-bottom: 0.5em;
            padding-top: 0.5em; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.segments .segment strong {
              font-size: 0.9em; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.segments .segment .icon {
              margin-right: 0.35em; }
            .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.segments .segment .AppointmentStatusTimeline__time {
              display: inline-block;
              width: 90%; }
        .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.-narrow .ui.segment {
          font-size: 0.8em;
          padding: 0.4em; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.-narrow .ui.segment .AppointmentStatusTimeline__time {
            width: 88%;
            line-height: 8px; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.-narrow .ui.segment .icon {
            font-size: 0.9em;
            margin-right: 0.05em;
            line-height: 8px; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.-narrow .ui.segment .-date {
            background: rgba(255, 255, 255, 0.93);
            display: none;
            height: 100%;
            left: 0;
            padding-top: 0.5em;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%; }
          .AppointmentDetail .AppointmentDetail__content .AppointmentDetail__content__inner .AppointmentStatusTimeline.-narrow .ui.segment:hover .-date {
            display: block; }
  .AppointmentDetail .AppointmentDetail__side__car {
    padding: 3px 3px 0 3px; }
    .AppointmentDetail .AppointmentDetail__side__car .MCCButton,
    .AppointmentDetail .AppointmentDetail__side__car .SButton,
    .AppointmentDetail .AppointmentDetail__side__car .ServiceBox-Widget-Iframe,
    .AppointmentDetail .AppointmentDetail__side__car .RDWDetail {
      margin-bottom: 3px; }
  .AppointmentDetail .FileDropDown {
    margin-right: 5px; }
    .AppointmentDetail .FileDropDown .List {
      box-shadow: none; }
      .AppointmentDetail .FileDropDown .List .Adder {
        background-color: #0086d6; }
      .AppointmentDetail .FileDropDown .List a {
        font-size: 0.75rem;
        line-height: 0.85rem;
        margin: 7px 0 2px 5px; }
    .AppointmentDetail .FileDropDown .label {
      text-align: center; }
      .AppointmentDetail .FileDropDown .label span {
        color: #fff;
        display: inline-block;
        font-family: inherit;
        font-size: 13px;
        font-weight: 600;
        transform: translateX(3px); }
  .AppointmentDetail .ui.disabled.input,
  .AppointmentDetail .ui.input:not(.disabled) input[disabled] {
    opacity: 1; }
  .AppointmentDetail .InlineInput .InlineInput__valueinput > div {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%; }
    .AppointmentDetail .InlineInput .InlineInput__valueinput > div .react-datepicker__input-container input {
      border-radius: 0;
      background: transparent !important;
      border: none !important;
      border-bottom-color: currentcolor;
      border-bottom-style: none;
      border-bottom-width: medium;
      border-bottom: 1px solid #ccc !important;
      font-weight: 700 !important;
      padding-left: 0.2em;
      padding: 0.67857143em 0.2em;
      width: 7em; }
      .AppointmentDetail .InlineInput .InlineInput__valueinput > div .react-datepicker__input-container input:focus-visible {
        outline: none; }
  .AppointmentDetail .react-datepicker__portal .react-datepicker__header {
    padding-top: 8px; }
  .AppointmentDetail .react-datepicker__portal .react-datepicker__current-month {
    margin-bottom: 10px; }

.preferred-communication-chanel-container {
  margin-top: 14px; }
  .preferred-communication-chanel-container div.preferred-communication-channel-label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #2185d0;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-left: 4px;
    border-radius: 50%;
    vertical-align: 3px; }
    .preferred-communication-chanel-container div.preferred-communication-channel-label i.icon {
      width: 100%;
      height: 100%;
      margin: 0px;
      font-size: 7px; }

.status-segment-info-time {
  font-size: 16px;
  font-weight: bold;
  color: #494949;
  margin-bottom: 0.25em; }

.status-segment-info-name {
  font-size: 16px;
  color: #494949;
  word-break: break-all; }

.status-segment-info-receiver {
  font-size: 16px;
  color: #494949;
  word-break: break-all; }

@media (max-width: 1500px) {
  .AppointmentDetail {
    font-size: 12px; }
    .AppointmentDetail .AppointmentDetail__side ul li span {
      font-size: 0.7em; }
      .AppointmentDetail .AppointmentDetail__side ul li span .-detail-value {
        font-size: 0.9em; } }

@media (min-width: 1712px) {
  .hideOnBigScreen {
    display: none !important; } }

@media (max-width: 1711px) {
  .hideOnSmallerScreen {
    display: none !important; } }

#_svcbox_btt {
  background: transparent;
  border-radius: 0;
  border: 1px solid #ccc;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  padding: 6px 0;
  text-transform: uppercase;
  width: 97%; }

.AppointmentStatusTimeline__remark {
  color: #494949;
  font-style: italic; }

.CarInShopPrint {
  margin: 20px auto;
  font-size: 20px; }
  @media print {
    .CarInShopPrint {
      width: 100% !important;
      margin: 22mm 64mm !important;
      font-size: 16px; } }
  @media print {
    .CarInShopPrint .car_details > div {
      margin: 0; } }
  .CarInShopPrint .reg_nr {
    margin-top: 7mm;
    font-size: 40px; }
    @media print {
      .CarInShopPrint .reg_nr {
        font-size: 34px; } }
  .CarInShopPrint .vin_nr {
    margin-top: 8mm;
    font-size: 40px; }
    @media print {
      .CarInShopPrint .vin_nr {
        font-size: 34px; } }
  .CarInShopPrint .wo_nr {
    margin-top: 8mm;
    font-size: 40px; }
    @media print {
      .CarInShopPrint .wo_nr {
        font-size: 34px; } }
  .CarInShopPrint .user {
    margin-top: 3mm; }
  .CarInShopPrint .customerFirstnameSurname {
    margin-top: 3mm; }

.ui.small.modal.transition.visible.active.CarInShopPrintModal.CarInShopPrintModalKiosk {
  height: 300px;
  overflow-y: scroll; }

#car_in_shop_a4_content {
  width: 100% !important;
  margin: 0 !important;
  padding: 25px !important; }

@page {
  #car_in_shop_a4_content {
    size: A4 !important;
    margin: 22mm 64mm !important; } }
  #car_in_shop_a4_content div {
    width: 100% !important;
    margin: 0 !important;
    padding: 10px !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important; }
  #car_in_shop_a4_content .car_in_shop_print_large {
    font-size: 34px !important;
    line-height: 36px !important; }
  #car_in_shop_a4_content .car_in_shop_print_bold {
    font-weight: bold !important; }

#car_in_shop_label_content {
  width: 62mm !important;
  margin: 0 auto !important;
  padding: 0 !important; }
  @media print {
    #car_in_shop_label_content {
      height: 29mm !important; } }
  @media screen {
    #car_in_shop_label_content {
      margin: 25px auto !important; } }

@page {
  #car_in_shop_label_content {
    size: 62mm 29mm !important;
    margin: 0 !important; } }
  #car_in_shop_label_content div {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 16px !important;
    line-height: 17px !important;
    text-align: center !important; }

#car_in_shop_kiosk_label_content {
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  overflow: visible;
  margin-top: 46%; }
  @media print {
    #car_in_shop_kiosk_label_content {
      display: block;
      width: 19.1mm !important;
      height: 279.4mm !important;
      margin: 0mm !important;
      padding: 0mm !important; } }
  #car_in_shop_kiosk_label_content div {
    width: fit-content !important;
    height: fit-content !important;
    margin: 0mm !important;
    padding: 0mm !important;
    margin-right: 5mm !important;
    break-inside: avoid !important;
    word-break: keep-all !important;
    white-space: nowrap !important; }
  @media print {
    #car_in_shop_kiosk_label_content div {
      font-size: 6mm !important;
      margin-bottom: 19mm !important; } }

.bill-note-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 9.5px 14px;
  margin-top: 10px; }
  .bill-note-container .user-details {
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
    margin-right: 8px;
    color: #767777; }

.field.add-appointment-intervention-thumb-toggle-container .ThumbToggle .ui.label {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .field.add-appointment-intervention-thumb-toggle-container .ThumbToggle .ui.label .icon {
    margin: 0px;
    font-size: 16px; }

.ui.small.modal.transition.visible.active.add-intervnetion-subitem-modal .header {
  display: flex;
  justify-content: space-between; }
  .ui.small.modal.transition.visible.active.add-intervnetion-subitem-modal .header svg {
    font-size: 20px;
    color: #767676;
    cursor: pointer; }

.ui.big.modal.transition.visible.active.add-intervention-modal .header {
  display: flex;
  padding: 1.5rem;
  border-bottom: 1px solid #e2e2e3;
  margin-top: 0px; }
  .ui.big.modal.transition.visible.active.add-intervention-modal .header .add-intervention-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 2px;
    background-color: #21ba45;
    border-radius: 3px; }
    .ui.big.modal.transition.visible.active.add-intervention-modal .header .add-intervention-icon svg {
      font-size: 10px; }
  .ui.big.modal.transition.visible.active.add-intervention-modal .header h3 {
    margin-top: 0px; }

.ui.big.modal.transition.visible.active.add-intervention-modal .actions {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.ui.big.modal.transition.visible.active.add-intervention-modal .ui.segment.subitem-required-container {
  border: 1px solid #912d2b;
  background-color: #fff6f6; }
  .ui.big.modal.transition.visible.active.add-intervention-modal .ui.segment.subitem-required-container span {
    color: #912d2b; }
