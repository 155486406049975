.WarrantyDashboard > .PinTable {
  max-width: 1920px;
  margin: 0 auto !important;
  padding: 0 1rem; }

.WarrantyDashboard .SnoozeDeleteLabel {
  height: 26px;
  cursor: pointer;
  margin: 0;
  transform: translateY(-2px); }
  .WarrantyDashboard .SnoozeDeleteLabel .icon {
    margin: 0 auto !important;
    opacity: 1 !important; }

.WarrantyDashboard .total-appointments {
  margin-left: 4px; }

.WarrantyDashboard .seven.wide.column.warranty-dashboard-status-export-container.-no-padding {
  display: flex;
  justify-content: flex-end;
  margin-left: auto; }
  .WarrantyDashboard .seven.wide.column.warranty-dashboard-status-export-container.-no-padding .warranty-dashboard-status-filter-container {
    width: 70%; }
  .WarrantyDashboard .seven.wide.column.warranty-dashboard-status-export-container.-no-padding .export-button {
    margin-left: 5px; }

.Warranty_media_popup .AppointmentChecklistGroup__item__media {
  background-color: #fafafa;
  border-top: 1px solid #f6f6f6;
  padding-top: 5px !important; }
  .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img {
      background-color: #eee;
      cursor: pointer;
      height: 150px;
      width: 266px; }
      .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img:hover {
        opacity: 0.9; }
    .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section {
      background: #fff;
      border: 1px solid #eee;
      border-top: none;
      height: 2em;
      padding: 0.5em; }
      .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section .icon:hover {
        cursor: pointer;
        opacity: 0.8; }
  .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper {
    display: inline-block;
    margin: 0 20px 10px 0; }
    .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper .file-wrapper-inner-containers {
      display: flex;
      align-items: flex-end; }
  .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper video {
      height: 150px;
      width: auto;
      transform: translateY(7px);
      position: relative;
      bottom: 7px; }
    .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section {
      background: #fff;
      border: 1px solid #eee;
      border-top: none;
      height: 2em;
      padding: 0.5em; }
      .Warranty_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section .icon:hover {
        cursor: pointer;
        opacity: 0.8; }
