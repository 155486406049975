.note-container {
  border: 1px solid #e2e2e3;
  background-color: #fbbd0805;
  padding: 9px 11px 15px 11px;
  color: #000000cc;

  div:first-child {
    margin-bottom: 5px;
    font-weight: 600;
  }
}
