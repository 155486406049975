.NoteSuggestions {
  .NoteSuggestionsHeader {
    background-color: #fff;
    font-size: 24px;
    padding: 25px 0px 25px 25px;
  }

  .NoteSuggestionsContent {
    padding: 15px;

    .note-type-title {
      font-size: 12.6px;
      font-weight: bold;
      margin-bottom: 5px;
      margin-left: 10px;
    }

    .note-type-container {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e2e2e3;
      background-color: #fff;

      .note-type-info {
        width: 40%;
        display: flex;
        margin-left: 10px;

        i {
          margin-right: 10px;
        }

        .phone-icon {
          margin-top: 3px;
          margin-right: 10px;
          color: #46b046;
        }

        .parking-icon {
          margin-top: 3px;
          margin-right: 10px;
          margin-left: 3px;
          color: #46b046;
        }
      }

      .note-type-label-container {
        cursor: pointer;
        height: 100%;

        .ui.green.label {
          height: 100%;
          width: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;

          svg {
            width: 16px;
            height: 14.22px;
          }
        }
      }
    }
  }
}

.NoteSuggestionsModalHeader.header {
  .ui.header.modal-header {
    display: flex;
    font-size: 18px;
  }
}

.NoteSuggestionsModalContent {
  ul {
    padding: 0px;
  }

  .suggestion-modal-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .add-suggestion-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.suggestion-content-container {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;

  .ui.right.labeled.input {
    width: 100%;

    .ui.label.label {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0px;
      }
    }
  }

  .ui.basic.label.suggestion-content-delete-label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;

    svg {
      width: 12.25px;
      height: 14px;
    }
  }
}

input:focus {
  border-color: #dededf !important;
}

.sortableHelper {
  z-index: 9999;
}
