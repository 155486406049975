.SnoozeTable {
  font-size: 1rem;
  line-height: 25px; }
  .SnoozeTable .Table__no-results {
    margin-top: 20px; }
  .SnoozeTable .-internal {
    margin-left: -5px;
    color: #fe0202;
    font-weight: bold;
    padding: 5px;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .SnoozeTable .ReactTable.-floated-table .rt-tbody,
  .SnoozeTable .ReactTable.-floated-table .rt-thead.-header {
    width: auto;
    margin: 0; }
  .SnoozeTable .QuestionStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 3px;
    float: right;
    text-align: center;
    width: 2.5em; }
    .SnoozeTable .QuestionStatus > span {
      color: #fff !important;
      margin-left: 4px; }
  .SnoozeTable .Snoozer__Label {
    width: 31px; }
  .SnoozeTable .rt-tbody {
    overflow: hidden !important; }
    .SnoozeTable .rt-tbody .rt-tr-group {
      cursor: initial !important; }
  .SnoozeTable .pagination-bottom .-pagination .-next button:enabled {
    background-color: #46923d;
    color: white; }
    .SnoozeTable .pagination-bottom .-pagination .-next button:enabled:hover {
      background-color: #377730;
      color: white; }
  .SnoozeTable .pagination-bottom .-pagination .-previous button:enabled {
    background-color: #46923d;
    color: white; }
    .SnoozeTable .pagination-bottom .-pagination .-previous button:enabled:hover {
      background-color: #377730;
      color: white; }

.customer-popup .lease-company-tag {
  margin-bottom: 6px; }
  .customer-popup .lease-company-tag span {
    background-color: #43ba45;
    color: white;
    border-radius: 3px;
    font-size: 12px;
    padding: 6px; }

.Snooze_media_popup .AppointmentChecklistGroup__item__media {
  background-color: #fafafa;
  border-top: 1px solid #f6f6f6;
  padding-top: 5px !important; }
  .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img {
      background-color: #eee;
      cursor: pointer;
      height: 150px;
      width: 266px; }
      .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper img:hover {
        opacity: 0.9; }
    .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section {
      background: #fff;
      border: 1px solid #eee;
      border-top: none;
      height: 2em;
      padding: 0.5em; }
      .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Image-Wrapper section .icon:hover {
        cursor: pointer;
        opacity: 0.8; }
  .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper {
    display: inline-block;
    margin: 0 20px 10px 0; }
    .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Files-Wrapper .file-wrapper-inner-containers {
      display: flex;
      align-items: flex-end; }
  .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper video {
      height: 150px;
      width: auto;
      transform: translateY(7px);
      position: relative;
      bottom: 7px; }
    .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section {
      background: #fff;
      border: 1px solid #eee;
      border-top: none;
      height: 2em;
      padding: 0.5em; }
      .Snooze_media_popup .AppointmentChecklistGroup__item__media .AppointmentChecklistGroup__item__media__Video-Wrapper section .icon:hover {
        cursor: pointer;
        opacity: 0.8; }

.ReactTable.SnoozeTable.-floated-table .rt-noData {
  top: 100px !important; }
