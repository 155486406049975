.dpo-info {
  display: inline-block;
  height: 100%;
  overflow-y: auto; }

.dpo-info-inner {
  padding: 20px; }

.dpo-info-inner section {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 5px 15px 0; }

.dpo-info-inner section p {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px; }

.dpo-info-inner section p:last-of-type {
  border: none; }

.dpo-info-inner section p strong {
  width: 40%;
  display: inline-block; }
