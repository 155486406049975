.DetailLogTable.ReactTable.-floated-table .rt-tbody {
  min-height: 200px; }
  .DetailLogTable.ReactTable.-floated-table .rt-tbody .rt-tr-group {
    height: auto;
    max-height: none; }
    .DetailLogTable.ReactTable.-floated-table .rt-tbody .rt-tr-group .full-description-row {
      white-space: pre-wrap;
      line-height: 20px; }

.DetailLogTable.ReactTable.-floated-table .rt-noData {
  top: 45%;
  font-size: 1rem; }

.ui.large.modal.AppointmentDetailLog {
  width: 90%; }
  .ui.large.modal.AppointmentDetailLog .header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .ui.large.modal.AppointmentDetailLog .uploaded-files-text {
    color: #0099ff;
    text-decoration: underline;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer; }

.ui.small.modal.transition.visible.active.uploaded-images-modal .content {
  word-wrap: break-word; }
  .ui.small.modal.transition.visible.active.uploaded-images-modal .content a {
    display: block;
    cursor: pointer;
    margin-bottom: 10px; }

.ui.small.modal.transition.visible.active.uploaded-images-modal .header {
  display: flex;
  justify-content: space-between; }
  .ui.small.modal.transition.visible.active.uploaded-images-modal .header svg {
    color: #7d7d7d;
    cursor: pointer; }

.ui.small.modal.transition.visible.active.uploaded-images-modal .copy-all-links-button {
  background-color: #2eb8b8;
  color: #fff; }
  .ui.small.modal.transition.visible.active.uploaded-images-modal .copy-all-links-button svg {
    margin-right: 3px; }
