.pin-toggle {
  display: flex;
  padding: 10px; }
  .pin-toggle .ui.toggle.checkbox input:checked ~ .box:before,
  .pin-toggle .ui.toggle.checkbox input:checked ~ label:before,
  .pin-toggle .ui.toggle.checkbox input:focus:checked ~ label::before {
    background-color: #46923d !important; }
  .pin-toggle .toggle label {
    margin-left: 10px; }
  @media (min-width: 1920px) {
    .pin-toggle {
      margin-right: 10px !important; } }

.ui.grid > .PinFilters {
  padding: 1rem 0; }
  .ui.grid > .PinFilters .sixteen.wide {
    padding-left: 0;
    padding-right: 0; }
  .ui.grid > .PinFilters .pin-filter-group {
    display: flex;
    width: 100%; }
    .ui.grid > .PinFilters .pin-filter-group > .field {
      flex: 1 1 50%; }
      .ui.grid > .PinFilters .pin-filter-group > .field.wide-dropdown {
        flex-basis: 80%; }
      .ui.grid > .PinFilters .pin-filter-group > .field.pin-toggle {
        flex-basis: 20%;
        margin-right: 10px; }

.pin-dropdown-datepicker {
  display: flex; }
  .pin-dropdown-datepicker h4 {
    display: inline-block;
    margin: 0;
    padding: 0.67857143em 1em; }
