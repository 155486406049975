.CarDrivingTrend .MileageChart-Wrapper {
  border: 1px solid #eee;
  padding: 0.75em;
  margin-bottom: 1em; }
  .CarDrivingTrend .MileageChart-Wrapper .MileageChart .MileageChart__Legend {
    margin-bottom: 1em; }
    .CarDrivingTrend .MileageChart-Wrapper .MileageChart .MileageChart__Legend .MileageChart__Legend__Text {
      display: inline-block;
      margin-right: 1%;
      padding: 0.15em 0;
      text-align: center;
      width: 19%; }
