.-no-margin {
  margin: 0 !important; }

.-margin-right-1-rem {
  margin-right: 1rem !important; }

.-margin-right-5 {
  margin-right: 5px !important; }

.-margin-right-10 {
  margin-right: 10px !important; }

.-margin-right-15 {
  margin-right: 15px !important; }

.-margin-bottom-5 {
  margin-bottom: 5px !important; }

.-margin-bottom-10 {
  margin-bottom: 10px !important; }

.-margin-bottom-20 {
  margin-bottom: 20px !important; }

.-margin-left-5 {
  margin-left: 5px !important; }

.-margin-left-10 {
  margin-left: 10px !important; }

.-margin-left-15 {
  margin-left: 15px !important; }

.-margin-left-20 {
  margin-left: 20px !important; }

.-margin-left-30 {
  margin-left: 30px !important; }

.-margin-top-5 {
  margin-top: 5px !important; }

.-margin-top-10 {
  margin-top: 10px !important; }

.-margin-top-15 {
  margin-top: 15px !important; }

.-margin-top-20 {
  margin-top: 20px !important; }

.-margin-top-30 {
  margin-left: 30px !important; }

.-padding-left-10 {
  padding-left: 10px; }

.-padding-top-20 {
  padding-top: 20px !important; }

.-no-padding {
  padding: 0 !important; }

.-no-padding-top {
  padding-top: 0 !important; }

.-no-padding-bottom {
  padding-bottom: 0 !important; }

.-no-padding-right {
  padding-right: 0 !important; }

.-no-padding-left {
  padding-left: 0 !important; }

.-padding-tb-50 {
  padding: 50px 0 !important; }

.-text-justify {
  text-align: justify; }

.-text-center {
  text-align: center !important; }

.-text-right {
  text-align: right !important; }

.-text-left {
  text-align: left !important; }

.-text-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 100%; }

.-text-overflow-unset {
  text-overflow: unset !important; }

.-text-weight-300 {
  font-weight: 300; }

.-table-header {
  font-size: 0.9em;
  font-weight: 600; }

.-text-underline {
  text-decoration: underline; }

.-text-underline-hover:hover {
  text-decoration: underline; }

.-text-uppercase {
  text-transform: uppercase !important; }

.-cursor-pointer {
  cursor: pointer; }
  .-cursor-pointer:hover {
    color: green; }

.-cursor-pointer-trash {
  cursor: pointer; }
  .-cursor-pointer-trash:hover {
    color: #b03060; }

.-cursor-pointer-no-color {
  cursor: pointer; }

.-cursor-not-allowed {
  cursor: not-allowed; }

.-no-click {
  cursor: default;
  pointer-events: none; }

.-link-plain {
  color: inherit;
  text-decoration: none; }

.-z-index-100 {
  position: relative;
  z-index: 100; }

.-z-index-99 {
  position: relative;
  z-index: 99; }

.-scrollable-dropdown .menu {
  max-height: 400px;
  overflow-y: auto; }

.-pull-right {
  display: inline-block !important;
  float: right !important; }

.-pull-left {
  float: left !important; }

.-center-vertically {
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%); }

.-border-top-2 {
  border-top: 2px solid; }

.-border-color-eee {
  border-color: #eee; }

.-border-right-none {
  border-right: none !important; }

.-overflow-inherit {
  overflow: inherit !important; }

.-overflow-visible {
  overflow: visible; }

.-background-yellow {
  background: yellow; }

.-translate-y-1 {
  transform: translateY(1px); }

.-display-inline-block {
  display: inline-block !important; }

.-display-none {
  display: none !important; }
