.elipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block; }

.no-elipsis {
  white-space: normal !important;
  text-overflow: inherit !important; }

.ImportantItems-notes {
  padding-bottom: 0.725rem;
  width: 100%; }
  .ImportantItems-notes .row.note-subline-row {
    padding: 0; }
    .ImportantItems-notes .row.note-subline-row .column {
      padding-bottom: 0 !important; }
      .ImportantItems-notes .row.note-subline-row .column .note-subline {
        background-color: #f4fcc1;
        border: 1px solid #ebebe8;
        border-bottom: 0;
        padding: 0.725rem; }
        .ImportantItems-notes .row.note-subline-row .column .note-subline.keep-parts {
          background-color: #fdf3e0; }
    .ImportantItems-notes .row.note-subline-row:last-child .note-subline {
      border-bottom: 1px solid #ebebe8; }

.receptionist-remarks-container .note-row {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative; }
  .receptionist-remarks-container .note-row > div {
    display: inline-flex;
    align-items: center; }
    .receptionist-remarks-container .note-row > div:first-child {
      flex: 1;
      width: 0;
      min-width: 0;
      line-height: 16px; }
    .receptionist-remarks-container .note-row > div:nth-child(2) {
      padding-left: 10px; }
  .receptionist-remarks-container .note-row .note-icon {
    display: flex;
    position: relative;
    bottom: 2px;
    margin-right: 8px;
    flex-shrink: 0; }
    .receptionist-remarks-container .note-row .note-icon > .ui.label.label-icon {
      display: inline-flex;
      justify-content: center;
      width: 34px;
      margin-right: 4px; }
    .receptionist-remarks-container .note-row .note-icon .ui.label.label-date {
      display: flex;
      justify-content: center;
      min-width: 90px; }
    .receptionist-remarks-container .note-row .note-icon .FileDropDown .Initiator {
      text-decoration: none; }
    .receptionist-remarks-container .note-row .note-icon .FileDropDown .ui.label {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      width: 45px; }
      .receptionist-remarks-container .note-row .note-icon .FileDropDown .ui.label i.attach.icon {
        color: #2185d0;
        font-size: 14px; }
      .receptionist-remarks-container .note-row .note-icon .FileDropDown .ui.label span {
        color: #5d5d5d; }
  .receptionist-remarks-container .note-row .note-details .note-date {
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
    margin-right: 8px;
    color: #767777; }
  .receptionist-remarks-container .note-row .note-details .ui.label {
    position: relative;
    cursor: pointer;
    bottom: 2px; }
  .receptionist-remarks-container .note-row .note-details .placeholder-element {
    display: inline-block;
    width: 36px; }
