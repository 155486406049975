.FloatingActions {
  bottom: 48px;
  position: absolute;
  right: 48px;
  text-align: center;
  width: 65px;
  z-index: 500; }
  .FloatingActions:hover {
    cursor: pointer; }
  .FloatingActions .action {
    background: #e73737;
    border-radius: 50%;
    color: #fff;
    font-size: 2.5em;
    font-weight: 300;
    height: 45px;
    margin-top: 11px;
    margin-left: 10px;
    padding-top: 4px;
    text-align: center;
    width: 45px;
    z-index: 500; }
    .FloatingActions .action:hover:before {
      content: attr(label);
      position: absolute;
      font-size: 14px;
      right: 60px;
      width: 170px;
      text-align: center;
      padding: 10px;
      background: #ff5050;
      color: white;
      border-radius: 5px;
      box-shadow: 0 2px 3px 0 #ababab; }
    .FloatingActions .action .icon {
      margin: 0; }
  .FloatingActions .main {
    background: #e73737;
    border-radius: 50%;
    color: #fff;
    font-size: 2.5em;
    font-weight: 300;
    height: 65px;
    margin-top: 11px;
    padding-top: 22px;
    width: 65px; }
