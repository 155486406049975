.CarTyres .tyres-details {
  width: 100%;
  font-size: 18px; }
  .CarTyres .tyres-details .tyre-season-icon {
    margin-left: 4px; }
  .CarTyres .tyres-details .storage-location {
    color: #46bf5e;
    margin-left: 10px; }
    .CarTyres .tyres-details .storage-location .storage-location-value {
      color: #5b7b8c;
      font-size: 18px; }
  .CarTyres .tyres-details .in-storage-delete-btn {
    width: 34px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e7e8e9;
    border-radius: 4px !important; }
    .CarTyres .tyres-details .in-storage-delete-btn .in-storage-delete-icon {
      width: 12.5px;
      height: 14px;
      align-self: center;
      margin-left: 1px;
      color: #ff0202; }

.CarTyres .on-car-title-container {
  display: flex;
  align-items: center; }
  .CarTyres .on-car-title-container strong {
    margin-right: 5px; }
  .CarTyres .on-car-title-container .label {
    margin-bottom: 2px; }

.CarTyres .tyres-heading-section {
  background-color: #f8f8f8;
  text-align: center;
  padding: 6px 0px; }
  .CarTyres .tyres-heading-section span {
    font-weight: 600;
    color: #262626;
    margin-right: 10px;
    font-size: 1.4em; }

.CarTyres .tyre-container {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 20px;
  padding: 0px 15px; }
  .CarTyres .tyre-container .tyre-inner-container {
    display: inline-block;
    padding: 0 5px 0 0; }
    .CarTyres .tyre-container .tyre-inner-container.right-tyre {
      text-align: right;
      padding: 0 0 0 5px; }
      .CarTyres .tyre-container .tyre-inner-container.right-tyre .tyre-details .right-profile {
        margin-right: 10px; }
    .CarTyres .tyre-container .tyre-inner-container .tyre-manufacturer {
      margin-bottom: 20px; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-manufacturer > div {
        font-size: 16px;
        color: #5b7b8c; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-manufacturer .left-season-icon {
        margin-right: 3px; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-manufacturer .right-season-icon {
        margin-left: 3px; }
    .CarTyres .tyre-container .tyre-inner-container .tyre-details {
      margin-bottom: 10px; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-details > span {
        color: #5b7b8c;
        margin-left: 10px;
        font-size: 16px; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-details .tyre-details-box {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 2px solid;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        font-weight: 600;
        font-size: 20px; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-details .orange-status {
        background-color: #fbdfca;
        border-color: #eb7a1f;
        color: #eb7a1f; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-details .green-status {
        background-color: #e5ffe8;
        border-color: #118b1f;
        color: #118b1f; }
      .CarTyres .tyre-container .tyre-inner-container .tyre-details .red-status {
        background-color: #fbd1d1;
        border-color: #eb1f1f;
        color: #eb1f1f; }
  .CarTyres .tyre-container .truck-tyre-inner-container:nth-child(even) {
    text-align: right; }
    .CarTyres .tyre-container .truck-tyre-inner-container:nth-child(even) div {
      margin-left: 10px; }
  .CarTyres .tyre-container .truck-tyre-inner-container {
    width: 23%; }

.CarTyres header {
  padding: 1em 1em 0 !important; }
  .CarTyres header .label {
    border-radius: 2px;
    font-size: 1.2em; }
    .CarTyres header .label .icon {
      margin: 0; }

.CarTyres .ui.padded.segment {
  border-radius: 0;
  border: none;
  font-size: 0.9em;
  height: 4em;
  padding: 2.1em 0.5em 0.5em 0.5em;
  text-align: center; }
  .CarTyres .ui.padded.segment .label {
    border-radius: 0;
    font-size: 0.7em;
    font-weight: 400;
    text-transform: uppercase; }

.CarTyres .TyrePair {
  margin: 0 auto;
  padding: 1em;
  width: 98%; }
  .CarTyres .TyrePair:first-of-type {
    border-bottom: 1px solid #eee; }
  .CarTyres .TyrePair p {
    margin: 0;
    text-transform: uppercase; }
  .CarTyres .TyrePair .grid .column {
    margin: 0.5em 0;
    padding: 0; }
  .CarTyres .TyrePair .TyrePair__Single .segment {
    border-radius: 0;
    padding: 0; }
    .CarTyres .TyrePair .TyrePair__Single .segment .label {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      width: 25%; }
    .CarTyres .TyrePair .TyrePair__Single .segment span {
      display: inline-block;
      text-align: center;
      width: 70%; }
  .CarTyres .TyrePair .TyrePair__Single.TyrePair__Single-right {
    text-align: right; }
  .CarTyres .TyrePair .dbb-storage-location {
    display: flex;
    justify-content: space-between;
    font-weight: bold; }
    .CarTyres .TyrePair .dbb-storage-location span {
      float: right; }
