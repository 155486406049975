.DBBButton.ui.basic.buttons .-status-no-dbb,
.DBBButton.ui.basic.buttons .-status-no-dbb:active,
.DBBButton.ui.basic.buttons .-status-no-dbb:focus,
.DBBButton.ui.basic.buttons .-status-no-dbb:hover {
  background-color: #fff !important;
  color: #000 !important; }

.DBBButton.ui.basic.buttons .-status-no-appointment,
.DBBButton.ui.basic.buttons .-status-no-appointment:active,
.DBBButton.ui.basic.buttons .-status-no-appointment:focus,
.DBBButton.ui.basic.buttons .-status-no-appointment:hover {
  box-shadow: 0 0 0 1px red inset;
  background-color: #eee;
  color: red !important; }

.DBBButton.ui.basic.buttons .-status-waiting-appointment,
.DBBButton.ui.basic.buttons .-status-waiting-appointment:active,
.DBBButton.ui.basic.buttons .-status-waiting-appointment:focus,
.DBBButton.ui.basic.buttons .-status-waiting-appointment:hover {
  background-color: orange !important;
  color: #fff !important; }

.DBBButton.ui.basic.buttons .-status-valid-appointment,
.DBBButton.ui.basic.buttons .-status-valid-appointment:active,
.DBBButton.ui.basic.buttons .-status-valid-appointment:focus,
.DBBButton.ui.basic.buttons .-status-valid-appointment:hover {
  background-color: green !important;
  color: #fff !important; }

.DBBButton.ui.basic.buttons .button:first-child {
  padding: 0.78571429em 1.1em; }

.DBBButton.ui.basic.buttons .button:last-child {
  padding: 0.5em; }

.DBBButton.ui.basic.buttons .button_text {
  display: inline-block; }

.DBBButton.ui.basic.buttons .button_app_date {
  display: block;
  font-size: 11px;
  line-height: 13px; }

.DBBButton.ui.basic.buttons .checkedTire {
  border-radius: 50%;
  font-size: 0.9em;
  height: 20px;
  width: 20px;
  line-height: 1em;
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  min-width: 20px;
  min-height: 20px;
  padding: 0 !important; }
  .DBBButton.ui.basic.buttons .checkedTire.checkedTire {
    overflow: hidden;
    margin-bottom: 3px; }
    .DBBButton.ui.basic.buttons .checkedTire.checkedTire .checkedTire-tire {
      position: relative;
      left: 4px;
      top: 8px;
      font-size: 20px; }
    .DBBButton.ui.basic.buttons .checkedTire.checkedTire .checkedTire-check {
      position: relative;
      top: -23px;
      left: -3px;
      font-size: 9px; }
  .DBBButton.ui.basic.buttons .checkedTire i.icon {
    margin: 0; }
