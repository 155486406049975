.news-article-container {
  .article-title {
    font-weight: 500;
    margin-bottom: 20px;
  }

  #news-article-inner-container {
    scroll-behavior: smooth;
  }

  .article-subtitle {
    font-weight: 500;
    margin-top: -15px;
    margin-bottom: 20px;
  }

  .article-content {
    font-weight: 400;
    color: #717171;
    margin-bottom: 20px;
    padding-right: 10px;

    img {
      max-width: 100%;
    }
  }

  .article-author-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .circle {
      width: 25px;
      height: 25px;
      border: 2px solid #1dbf5e;
      background-color: #e5e6e6;
      border-radius: 50%;

      .article-author-image {
        display: block;
        width: 100%;
        height: 1005;
      }
    }

    .author {
      color: #717171;
      margin-left: 5px;
    }
  }

  .article-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tour-btn {
      padding: 5px 20px;
      border: 1px solid #1dbf5e;
      border-radius: 3px;
      font-weight: 300;
      cursor: pointer;
      text-transform: initial;
    }

    .later-btn {
      background-color: #fff;
      color: #1dbf5e;
      margin-right: 5px;

      &:hover {
        background-color: #fff;
        color: #1dbf5e;
      }
    }

    .read-btn {
      background-color: #1dbf5e;
      color: #fff;

      &:hover {
        background-color: #1dbf5e;
        color: #fff;
      }
    }

    .next-btn {
      margin-left: 20px;
      cursor: pointer;
      color: #717171;
      pointer-events: initial;
    }

    .prev-btn {
      margin-right: 20px;
      cursor: pointer;
      color: #717171;
      pointer-events: initial;
    }

    .control-btn-disabled {
      color: #bfbfbf;
      pointer-events: none;
    }

    .step-indicator {
      color: #717171;
      font-weight: 400;
    }
  }
}

.reactour__helper.helper.reactour__helper--is-open {
  padding-left: 36px;
  padding-right: 36px;

  @media (min-width: 768px) {
    width: 88%;
  }

  @media (min-width: 992px) {
    width: 1020px;
  }

  @media (min-width: 1200px) {
    width: 1080px;
  }

  @media (min-width: 1920px) {
    width: 1140px;
  }
}
