.AddAppointmentForm h1 {
  font-weight: 300; }

.AddAppointmentForm h2 {
  font-size: 1.3em;
  font-weight: 300;
  text-transform: capitalize; }
  .AddAppointmentForm h2 .icon {
    margin-right: 10px; }
  .AddAppointmentForm h2 .search {
    display: inline-block;
    margin-left: 1em;
    width: 30%; }
    .AddAppointmentForm h2 .search .input {
      width: 100%; }
      .AddAppointmentForm h2 .search .input input {
        padding: 0.6em; }
    .AddAppointmentForm h2 .search .results {
      max-height: 300px;
      overflow-y: auto;
      width: 100%; }
      .AddAppointmentForm h2 .search .results .message .header,
      .AddAppointmentForm h2 .search .results .empty .header {
        margin-top: 0 !important;
        font-size: 16px !important; }

.AddAppointmentForm .form {
  padding: 1.5em; }
  .AddAppointmentForm .form label {
    font-weight: 400 !important; }
  .AddAppointmentForm .form .ui.disabled.input {
    opacity: initial; }

.AddAppointmentForm .react-datepicker-wrapper {
  width: 100%; }
  .AddAppointmentForm .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
    .AddAppointmentForm .react-datepicker-wrapper .react-datepicker__input-container input {
      border-radius: 0 !important; }

.AddAppointmentForm .RDWButton {
  border: 1px solid green !important; }

.AddAppointmentForm .AddInterventionButton {
  margin-top: 1.25em !important; }

.AddAppointmentForm .AddedIntervention {
  margin-top: 2em !important; }

.AddAppointmentForm .actions {
  border-top: 1px solid #dededf;
  float: left;
  padding: 1em;
  width: 100%; }

.AddAppointmentForm .ThumbToggle {
  cursor: pointer; }
  .AddAppointmentForm .ThumbToggle .label {
    padding: 0.95em; }
    .AddAppointmentForm .ThumbToggle .label i {
      margin: 0 auto;
      font-size: 14px; }

.AddAppointmentForm .intervention-elements-toggle-container {
  display: flex;
  align-items: center; }

.rc-time-picker {
  width: 100%; }

.rc-time-picker-input {
  border-radius: 0 !important;
  height: 100%; }
