.InlineInput {
  display: flex;
  align-items: center;
  width: 100%; }
  .InlineInput .InlineInputIconTextareaSpan {
    width: 100%;
    position: relative; }
  .InlineInput > i.angle.icon {
    cursor: pointer;
    position: absolute;
    right: 7%; }

.InlineInputIconTextarea {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  margin-top: 6px !important;
  overflow: hidden;
  outline: none;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }
  .InlineInputIconTextarea.-collapsed {
    overflow: hidden;
    height: 34px !important; }

.InlineInputIconTextareaSpan .save {
  margin-left: 0.1em !important; }

.InlineInputIconTextareaSpan .m-t-0 {
  margin-left: 0.1em !important;
  margin-top: 0 !important; }
