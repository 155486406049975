.Leads .ui.selection.active.wide-dropdown .menu {
  min-width: fit-content; }

.Leads .ui.grid .ui.form .tire_profile label {
  font-weight: 400; }

.Leads .sixteen.wide.column.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px; }

.Leads .SnoozeTableContainer,
.Leads .CarLeadsTableContainer {
  max-width: 1920px;
  margin: 0 auto !important;
  padding: 0 0.25rem; }
  .Leads .SnoozeTableContainer .Table__no-results,
  .Leads .CarLeadsTableContainer .Table__no-results {
    margin-top: 20px; }

.Leads .leads-dropdown-datepicker {
  display: flex; }
  .Leads .leads-dropdown-datepicker h4 {
    display: inline-block;
    margin: 0;
    width: 160px;
    padding: 0.67857143em 1em; }
    @media (max-width: 1280px) {
      .Leads .leads-dropdown-datepicker h4 {
        width: 180px; } }

.Leads .export-btn-leads-type-container {
  display: flex; }
  .Leads .export-btn-leads-type-container div {
    display: inline-block; }
  .Leads .export-btn-leads-type-container .export-button {
    padding: 9px; }
  .Leads .export-btn-leads-type-container .more-filters-btn,
  .Leads .export-btn-leads-type-container .more-filters-btn:focus,
  .Leads .export-btn-leads-type-container .reset-filters-btn {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    margin-right: 10px; }
    .Leads .export-btn-leads-type-container .more-filters-btn:hover, .Leads .export-btn-leads-type-container .more-filters-btn:active,
    .Leads .export-btn-leads-type-container .more-filters-btn:focus:hover,
    .Leads .export-btn-leads-type-container .more-filters-btn:focus:active,
    .Leads .export-btn-leads-type-container .reset-filters-btn:hover,
    .Leads .export-btn-leads-type-container .reset-filters-btn:active {
      background-color: #f0f0f0; }
    .Leads .export-btn-leads-type-container .more-filters-btn.active,
    .Leads .export-btn-leads-type-container .more-filters-btn:focus.active,
    .Leads .export-btn-leads-type-container .reset-filters-btn.active {
      background-color: #e8e8e8; }
      .Leads .export-btn-leads-type-container .more-filters-btn.active:hover, .Leads .export-btn-leads-type-container .more-filters-btn.active:active,
      .Leads .export-btn-leads-type-container .more-filters-btn:focus.active:hover,
      .Leads .export-btn-leads-type-container .more-filters-btn:focus.active:active,
      .Leads .export-btn-leads-type-container .reset-filters-btn.active:hover,
      .Leads .export-btn-leads-type-container .reset-filters-btn.active:active {
        background-color: #f0f0f0; }
  .Leads .export-btn-leads-type-container .ui.selection.dropdown {
    padding: 11px;
    margin-right: 20px; }

.Leads .status-group .ui.button:first-child {
  border-radius: 5px 0px 0px 5px !important; }

.Leads .status-group .ui.button:last-child {
  border-radius: 0px 5px 5px 0px !important; }

.Leads .checklist_tag {
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 4px; }

.Leads .leads-toggle {
  display: flex;
  padding: 10px;
  margin-top: 8px; }
  .Leads .leads-toggle .ui.toggle.checkbox input:checked ~ .box:before,
  .Leads .leads-toggle .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #46923d !important; }
  .Leads .leads-toggle .toggle label {
    margin-left: 10px; }

.Leads .BackOrderLeadsTable .Table__no-results {
  margin-top: 100px; }

.Leads .CarLeadsTable {
  font-size: 1rem;
  line-height: 25px; }
  .Leads .CarLeadsTable .Table__no-results {
    margin-top: 20px; }
  .Leads .CarLeadsTable .ReactTable.-floated-table .rt-tbody,
  .Leads .CarLeadsTable .ReactTable.-floated-table .rt-thead.-header {
    width: auto;
    margin: 0; }
  .Leads .CarLeadsTable .QuestionStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 3px;
    float: right;
    text-align: center;
    width: 2.5em; }
    .Leads .CarLeadsTable .QuestionStatus > span {
      color: #fff !important;
      margin-left: 4px; }
  .Leads .CarLeadsTable .Snoozer__Label {
    width: 31px; }
  .Leads .CarLeadsTable .rt-tbody {
    overflow: hidden !important; }
    .Leads .CarLeadsTable .rt-tbody .rt-tr-group {
      cursor: initial !important; }
  .Leads .CarLeadsTable .pagination-bottom .-pagination .-next button:enabled {
    background-color: #46923d;
    color: white; }
    .Leads .CarLeadsTable .pagination-bottom .-pagination .-next button:enabled:hover {
      background-color: #377730;
      color: white; }
  .Leads .CarLeadsTable .pagination-bottom .-pagination .-previous button:enabled {
    background-color: #46923d;
    color: white; }
    .Leads .CarLeadsTable .pagination-bottom .-pagination .-previous button:enabled:hover {
      background-color: #377730;
      color: white; }

.Leads .react-datepicker-wrapper {
  width: 100%; }

.Leads .dbb-column-item {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Leads .dbb-column-item > svg {
    width: 25px;
    height: 25px; }
  .Leads .dbb-column-item .ui.circular.label {
    border-radius: 50%;
    float: left;
    font-size: 0.9em;
    height: 25px;
    line-height: 1em;
    margin: 0 2px 0 0 !important;
    min-height: 25px;
    min-width: 25px;
    padding: 6px 0 0 0 !important;
    text-align: center;
    transform: none;
    width: 25px; }
    .Leads .dbb-column-item .ui.circular.label.checkedTire {
      overflow: hidden; }
      .Leads .dbb-column-item .ui.circular.label.checkedTire .checkedTire-tire {
        position: relative;
        left: 5px;
        top: 3px;
        font-size: 25px; }
      .Leads .dbb-column-item .ui.circular.label.checkedTire .checkedTire-check {
        position: relative;
        top: -17px;
        left: -21px;
        font-size: 11px; }
    .Leads .dbb-column-item .ui.circular.label.bigTire {
      font-size: 1.5em;
      padding: 3px 0 0 0 !important; }
      .Leads .dbb-column-item .ui.circular.label.bigTire svg {
        width: 25px; }
    .Leads .dbb-column-item .ui.circular.label i.icon {
      margin: 0; }
    .Leads .dbb-column-item .ui.circular.label i.material-icons {
      font-size: 1em;
      padding: 0 1px; }

.Leads .total-appointments {
  margin-left: 4px; }

.ExportLeads-Modal .export-modal-content {
  text-align: center; }
