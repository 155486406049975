#zohohc-asap-web-button {
  left: -100px; }

.App {
  height: 100%; }
  .App .ui.segment.pushable {
    border-radius: 0 !important;
    border: none !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important; }
    .App .ui.segment.pushable .pusher {
      height: 100%; }
      .App .ui.segment.pushable .pusher .segment {
        height: 100%;
        margin: 0;
        padding: 0; }
        .App .ui.segment.pushable .pusher .segment .App__content-overlay {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.49);
          position: absolute;
          z-index: 600;
          display: none; }
          .App .ui.segment.pushable .pusher .segment .App__content-overlay.-sidebar-open-true {
            display: block; }
  .App .App__sidebar a {
    border-bottom: 1px solid #f4f4f4;
    color: #1a1a1a;
    float: left;
    width: 100%; }
    .App .App__sidebar a:hover {
      color: #000; }
  .App .App__sidebar .item {
    padding-left: 0.5em; }
    .App .App__sidebar .item:hover {
      background-color: #f8f8f8 !important;
      cursor: pointer; }
  .App .App__sidebar .main-sidebar {
    margin-bottom: -34%;
    min-height: 100%; }
  .App .App__sidebar .menu-section {
    background: #f5f5f5;
    border-top: 1px solid #eee;
    border: none;
    bottom: 0;
    color: #808080;
    letter-spacing: 1px;
    padding: 1em 0;
    text-transform: uppercase;
    text-align: left;
    padding-left: 20px;
    cursor: default; }
    .App .App__sidebar .menu-section p {
      text-align: center;
      margin-left: -2em; }
  .App .App__module {
    background-color: #f7f7f7;
    display: inline-block;
    height: calc(100% - 60px);
    overflow-x: hidden;
    width: 100%; }
  .App .App_module_news_stripe_shown {
    height: calc(100% - 97px); }

.ui.visible.left.overlay.sidebar {
  max-width: 250px;
  width: auto !important; }

.ui.labeled.icon.menu .item > .icon.App__sidebar__menu__item:not(.dropdown) {
  display: inline-block !important;
  float: left;
  font-size: 1em !important;
  margin-right: 0.75em !important; }

.App__sidebar.ui.labeled.icon.menu .item {
  text-align: left;
  text-transform: capitalize; }

input::-webkit-inner-spin-button {
  opacity: 1; }

.react-datepicker-popper {
  z-index: 2; }

.backorders-menu-icon {
  position: relative;
  top: 1px;
  right: 1px;
  font-weight: 600;
  margin-right: 0.75em;
  float: left;
  width: 1.18em;
  font-size: 1em; }

div > div > div > span > div:has(.websocket-warranty-notification) {
  min-width: 400px;
  max-width: 700px; }

.websocket-warranty-notification,
.websocket-warranty-notification-icon-container {
  cursor: pointer; }
