.DomainSettings .Segment {
  color: gray;
  font-size: 90%;
  float: right; }

.DomainSettings .elipsis {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
