.Service {
  background: #fff;
  padding: 15px !important;
  border: 1px solid #eee;
  margin-bottom: 15px;

  .list-content {
    display: inline-block;
  }

  .service-tag {
    margin-left: 5px;
    padding: 4px;
    color: #fff;
    border: 1px solid lightgray;
    font-size: 12px;
    padding: 1px 7px 3px 5px;
    border-radius: 5px;
  }
}
