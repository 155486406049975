.EditQuestion .content .description .QuestionElement {
  display: inline-block;
  margin-right: 10px !important;
  cursor: move;
  padding: 8px;
  line-height: 14px;
  margin-bottom: 0; }

.EditQuestion .content .description .ListItem {
  width: 100%;
  margin: 0 0 5px 0;
  cursor: move; }

.EditQuestion .content .description .ui.buttons button {
  margin-right: 3px; }

.EditQuestion .content .description .QuestionSettings__checkboxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-top: 12px;
  width: 100%; }
  .EditQuestion .content .description .QuestionSettings__checkboxes > label {
    margin-bottom: 12px; }
  .EditQuestion .content .description .QuestionSettings__checkboxes .template-checkbox {
    width: 100%; }
