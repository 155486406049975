.Devices {
  height: 100%;
  min-height: 95vh; }
  .Devices .DevicesTable {
    min-height: 90vh; }
    .Devices .DevicesTable .ReactTable {
      min-height: 90vh; }
    .Devices .DevicesTable .pagination-bottom .-pagination .-next button:enabled {
      background-color: #46923d;
      color: white; }
      .Devices .DevicesTable .pagination-bottom .-pagination .-next button:enabled:hover {
        background-color: #377730;
        color: white; }
    .Devices .DevicesTable .pagination-bottom .-pagination .-previous button:enabled {
      background-color: #46923d;
      color: white; }
      .Devices .DevicesTable .pagination-bottom .-pagination .-previous button:enabled:hover {
        background-color: #377730;
        color: white; }
  .Devices .text-underline {
    text-decoration: underline; }
  .Devices .space-position {
    position: absolute;
    transform: translate(-5px, 15px); }
  .Devices .space-header {
    transform: translateX(-20px); }

.DevicesFilters {
  width: 100%;
  margin: 20px 0;
  padding: 12px; }
  .DevicesFilters .ui.selection.dropdown {
    font-size: 14px;
    line-height: 14px;
    margin-right: 2px; }
