.ROBListData {
  .section-title {
    font-size: 14px;
    font-weight: 900;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 7px;
    border-top: 1px solid #e2e2e3;
    padding-top: 10px;
  }

  .section-title:first-child {
    font-style: normal;
  }

  .data-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 7px;
    color: #000000cc;
    font-size: 14px;
    user-select: none;

    div {
      word-wrap: break-word;
    }

    div:first-child {
      width: 74%;
      margin-right: 1%;
      font-weight: bold;
    }

    div:nth-child(2) {
      width: 25%;
      text-align: left;
    }
  }

  .data-row:last-child {
    margin-bottom: 0px;
  }

  .controls-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .controls-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      padding: 5px;
      background-color: #fff;
      text-align: center;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
