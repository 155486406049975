.NewsStripe {
  text-align: center;
  .stripe {
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    background-color: #cce8c9;
    color: #3e3e3c;

    div:first-child {
      width: 98%;
      display: inline-block;
    }
    div:nth-child(2) {
      width: 2%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #9ad194;
    }

    .link {
      font-weight: bold;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.NewsStripeArticleModal {
  color: #717171;
  .user-section {
    display: flex;
    align-items: center;
    .user-indicator {
      width: 25px;
      height: 25px;
      border: 2px solid #1dbf5e;
      border-radius: 50%;
      background-color: #e5e6e6;
      margin-right: 5px;
    }
  }
}
