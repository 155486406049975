.ContractAgreement {
  height: 100vh;
  overflow-y: auto; }
  .ContractAgreement .ContractAgreement__Intro {
    background-color: green;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.5px;
    padding: 14px 0; }
    .ContractAgreement .ContractAgreement__Intro .ContractAgreement__Intro__Content {
      width: 840px;
      margin: 0 auto; }
  .ContractAgreement .ContractAgreement__Content {
    margin: 4em auto 0 auto;
    width: 840px;
    border: 1px solid #eee; }
    .ContractAgreement .ContractAgreement__Content h1 {
      background-color: #f7f7f7;
      border-bottom: 1px solid #eee;
      font-size: 1.25em;
      font-weight: 400;
      margin-bottom: 0;
      padding: 14px; }
      .ContractAgreement .ContractAgreement__Content h1 .UserInfo {
        float: right;
        font-size: 0.9em; }
    .ContractAgreement .ContractAgreement__Content p {
      padding: 7px 14px;
      font-size: 15px; }
    .ContractAgreement .ContractAgreement__Content .ContractAgreement__Actions {
      background-color: #f7f7f7;
      border-top: 1px solid #eee;
      padding: 14px; }
      .ContractAgreement .ContractAgreement__Content .ContractAgreement__Actions button {
        border-radius: 3px !important; }
