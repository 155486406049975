.SubHeader {
  background: #fff;
  color: #665e5e;
  font-size: 0.85em;
  padding: 0; }
  .SubHeader .ui.selection.dropdown {
    border-color: #ececec;
    border-radius: 2px;
    color: inherit;
    text-transform: capitalize; }
  .SubHeader .SubHeader_title {
    color: #46923e;
    font-size: 1.7em;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 0;
    padding: 0.3em 0 0.3em 1rem !important; }
  .SubHeader .SubHeader_content {
    padding: 0.51rem 0 !important; }
    .SubHeader .SubHeader_content .SubHeader_content_filters {
      color: #665e5e;
      padding: 0 1rem !important;
      width: 100%; }
      .SubHeader .SubHeader_content .SubHeader_content_filters .SubHeader_content_title {
        display: inline-block;
        margin-right: 10px; }
      .SubHeader .SubHeader_content .SubHeader_content_filters .SubHeader_content_total_users {
        font-size: 1.2em;
        font-weight: 600;
        color: #665e5e; }
      .SubHeader .SubHeader_content .SubHeader_content_filters .DateOptionsPicker_datepicker-wrapper input {
        color: #665e5e; }
