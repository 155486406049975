.MCCButton.ui.button.-status-0, .MCCButton.ui.button.-status-0:active, .MCCButton.ui.button.-status-0:focus {
  background-color: #fff !important;
  color: #000 !important; }

.MCCButton.ui.button.-status-1, .MCCButton.ui.button.-status-1:active, .MCCButton.ui.button.-status-1:focus {
  background-color: #eaeaea !important;
  color: #c6c6c6 !important;
  pointer-events: none !important; }

.MCCButton.ui.button.-status-2, .MCCButton.ui.button.-status-2:active, .MCCButton.ui.button.-status-2:focus {
  background-color: red !important;
  color: #fff !important; }

.MCCButton.ui.button.-status-3, .MCCButton.ui.button.-status-3:active, .MCCButton.ui.button.-status-3:focus {
  background-color: orange !important;
  color: #fff !important; }

.MCCButton.ui.button.-status-4, .MCCButton.ui.button.-status-4:active, .MCCButton.ui.button.-status-4:focus {
  background-color: green !important;
  color: #fff !important; }

.MCCButton.ui.button.-status-5, .MCCButton.ui.button.-status-5:active, .MCCButton.ui.button.-status-5:focus {
  background-color: #62cbfd !important;
  color: #fff !important; }

.MCCButton.ui.button.-status-6, .MCCButton.ui.button.-status-6:active, .MCCButton.ui.button.-status-6:focus {
  background-color: #00008b !important;
  color: #fff !important; }

.MCCButton.ui.button.-status-error, .MCCButton.ui.button.-status-error:active, .MCCButton.ui.button.-status-error:focus {
  box-shadow: 0 0 0 1px red inset;
  background-color: #eee;
  color: red !important; }
