.AppointmentNoteModal .ui.header > span:not(.backorder-icon) {
  margin-left: 10px;
  line-height: 22px; }

.AppointmentNoteModal .react-datepicker-wrapper {
  width: 100%; }

.AppointmentNoteModal div.backorder-icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #6c0699;
  border-radius: 4px;
  width: 26px;
  height: 22px;
  bottom: 2px; }
  .AppointmentNoteModal div.backorder-icon > span {
    color: #fff;
    font-size: 11px;
    font-weight: 300; }

.AppointmentNoteModal .actions {
  width: 100%; }

.AppointmentNoteModal .NoteAttachmentsContainer .DragAndDropBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 35px;
  border: 1px dashed #dededf;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 20px; }
  .AppointmentNoteModal .NoteAttachmentsContainer .DragAndDropBox.-dragged-over {
    opacity: 0.3;
    border: 1px dotted #1f1f1f; }
  .AppointmentNoteModal .NoteAttachmentsContainer .DragAndDropBox.-attachments-error {
    border-color: red; }
  .AppointmentNoteModal .NoteAttachmentsContainer .DragAndDropBox span.browse-files {
    color: #2185d0;
    text-decoration: underline;
    cursor: pointer; }
  .AppointmentNoteModal .NoteAttachmentsContainer .DragAndDropBox .description {
    color: #b5b5b5;
    font-size: 0.9rem; }

.AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow {
  display: flex;
  padding: 8px;
  font-size: 16px;
  margin-bottom: 4px;
  width: 100%;
  justify-content: space-between; }
  .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileTitle {
    flex: 1;
    width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 10px; }
    .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileTitle svg {
      color: grey;
      margin-right: 10px; }
    .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileTitle a {
      cursor: pointer; }
  .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileInfo {
    justify-content: flex-end;
    font-size: 14px;
    font-style: italic;
    color: grey; }
    .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileInfo svg {
      margin-left: 24px;
      cursor: pointer; }
    .AppointmentNoteModal .NoteAttachmentsContainer .FileList .FileRow .FileInfo .FileAuthor {
      margin-right: 3px; }
