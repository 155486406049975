#pin-overview-content .info-section,
#pin-overview-content .pin-section {
  border: 2px solid rgba(187, 194, 198, 0.75);
  margin: 0;
  margin: 0 0 1rem 0;
  font-size: 1.1rem; }
  #pin-overview-content .info-section .section-header,
  #pin-overview-content .pin-section .section-header {
    font-weight: 700;
    color: #234357;
    background-color: #f3f5f7; }
  #pin-overview-content .info-section .section-content,
  #pin-overview-content .pin-section .section-content {
    color: #5c7c8d; }
    #pin-overview-content .info-section .section-content .section-content-row,
    #pin-overview-content .pin-section .section-content .section-content-row {
      margin-bottom: 12px;
      width: 100% !important; }
      #pin-overview-content .info-section .section-content .section-content-row .icon,
      #pin-overview-content .info-section .section-content .section-content-row .section-icon,
      #pin-overview-content .pin-section .section-content .section-content-row .icon,
      #pin-overview-content .pin-section .section-content .section-content-row .section-icon {
        margin-right: 6px;
        color: #21ba45; }

#pin-overview-content .full-width-section {
  color: #1d3d52;
  margin: 0 0 1rem 0; }
  #pin-overview-content .full-width-section .section-header {
    width: 100%;
    padding: 1rem;
    font-size: 1.25rem;
    color: #1d3d52;
    font-weight: 600; }
    #pin-overview-content .full-width-section .section-header > span {
      font-size: 12px; }
    #pin-overview-content .full-width-section .section-header.header-yellow {
      background-color: #f8e89e; }
    #pin-overview-content .full-width-section .section-header.header-blue {
      background-color: #2184d049; }
    #pin-overview-content .full-width-section .section-header.header-green {
      background-color: #bdf0c3; }
    #pin-overview-content .full-width-section .section-header.header-grey {
      background-color: #f7f7f7; }
  #pin-overview-content .full-width-section .section-content {
    padding: 0.4rem 1rem; }
  #pin-overview-content .full-width-section .section-content .pin-log,
  #pin-overview-content .full-width-section .check-content .pin-log {
    padding: 0.5rem 0;
    break-inside: avoid; }
    #pin-overview-content .full-width-section .section-content .pin-log .log-values .row,
    #pin-overview-content .full-width-section .check-content .pin-log .log-values .row {
      margin-bottom: 5px; }
  #pin-overview-content .full-width-section .section-content .content-note,
  #pin-overview-content .full-width-section .check-content .content-note {
    padding: 0.75rem 0; }
    #pin-overview-content .full-width-section .section-content .content-note .label,
    #pin-overview-content .full-width-section .check-content .content-note .label {
      margin-right: 6px; }
      #pin-overview-content .full-width-section .section-content .content-note .label .icon,
      #pin-overview-content .full-width-section .check-content .content-note .label .icon {
        margin-right: 0; }
  #pin-overview-content .full-width-section .section-content .images-container,
  #pin-overview-content .full-width-section .check-content .images-container {
    margin-bottom: 0.5rem; }
  #pin-overview-content .full-width-section .section-content .content-row,
  #pin-overview-content .full-width-section .check-content .content-row {
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #f1f3f4; }
    #pin-overview-content .full-width-section .section-content .content-row:last-child,
    #pin-overview-content .full-width-section .check-content .content-row:last-child {
      border-bottom: none;
      padding: 0;
      margin: 0; }
  #pin-overview-content .full-width-section .section-content .content-title,
  #pin-overview-content .full-width-section .check-content .content-title {
    display: grid;
    grid-template-columns: 90% 10%; }
    #pin-overview-content .full-width-section .section-content .content-title h2,
    #pin-overview-content .full-width-section .check-content .content-title h2 {
      font-size: 1.2rem;
      margin: 0;
      display: inline; }
  #pin-overview-content .full-width-section .section-content .content-text,
  #pin-overview-content .full-width-section .check-content .content-text {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 6px; }
  #pin-overview-content .full-width-section .item-status {
    display: flex;
    width: 30px;
    height: 27px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    border-radius: 4px !important;
    font-weight: 700;
    text-align: center;
    margin-left: auto; }
    #pin-overview-content .full-width-section .item-status > span {
      color: #fff !important; }
    #pin-overview-content .full-width-section .item-status .icon {
      margin: 0; }

#pin-overview-content .reg-container {
  width: 130px;
  height: 24px;
  margin-bottom: 8px;
  border: 2px solid #234457;
  border-radius: 0.15em; }
  #pin-overview-content .reg-container .reg-country {
    width: 20%;
    height: 100%;
    line-height: 100%;
    display: block;
    float: left;
    text-align: center;
    background-color: #40a7ff;
    padding-top: 3px;
    position: relative; }
    #pin-overview-content .reg-container .reg-country > div {
      color: #fff;
      font-size: 10px;
      bottom: 0px;
      position: absolute;
      width: 100%; }
  #pin-overview-content .reg-container .reg-nr {
    width: 80%;
    height: 100%;
    line-height: 100%;
    display: block;
    float: right;
    text-align: center;
    padding-top: 2px;
    font-weight: 500;
    font-size: 16px; }
  #pin-overview-content .reg-container.BE .reg-nr {
    background-color: #fff;
    color: #ac1713; }
  #pin-overview-content .reg-container.NL .reg-nr,
  #pin-overview-content .reg-container.LU .reg-nr {
    background-color: #efe24c;
    color: #234457; }
  #pin-overview-content .reg-container.FR .reg-nr {
    background-color: #fff;
    color: #000; }

#pin-overview-content .checks-loading-container {
  background-color: #fff;
  border: none;
  text-align: center;
  padding: 1rem; }
  #pin-overview-content .checks-loading-container section {
    border: none; }

.PinOverview-Modal > .header .ui.toggle.checkbox {
  padding-top: 0.5rem; }

.PinOverview-Modal > .header .pin-overview-header {
  float: right;
  margin-left: auto; }
