.KeyLockerCallForHelp .call_for_help_icon {
  color: #1ba753;
  width: 30px;
  height: 30px; }

.KeyLockerCallForHelp .call_for_help_content {
  display: flex;
  justify-content: center; }
  .KeyLockerCallForHelp .call_for_help_content p {
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0.5rem; }
