.ReactTable.Acl-table.-floated-table.-contained-large {
  .rt-table {
    .rt-thead .rt-th {
      border: none !important;
    }
  }

  .rt-th > div {
    div {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }

  .rt-td {
    i {
      position: relative;
      left: 30%;
    }
  }

  .rt-td:nth-child(9) {
    i {
      position: relative;
      left: 20%;
    }
  }

  .rt-noData {
    top: 100%;
  }
}

.six.wide.column.acl-filter-dropdown-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .filter-dropdown-label {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }

  .filter-dropdown {
    display: inline-block;
    width: 80%;
  }
}
