.PinTable {
  .ReactTable .rt-table {
    overflow: visible;
  }

  .Table__no-results {
    margin-top: 20px;
  }

  .ReactTable.-floated-table {
    .rt-tbody,
    .rt-thead.-header {
      width: auto;
      margin: 0;
    }
  }

  .rt-tbody {
    overflow: hidden !important;
    .rt-tr-group {
      cursor: initial !important;
    }

    .rt-td {
      align-self: center;
    }
  }

  .ClaimNrContainer,
  .SupportNrContainer,
  .VinNrContainer,
  .RefNrContainer {
    display: flex;
    cursor: pointer;

    i {
      display: none;
    }

    &:hover {
      i {
        display: inline-block;
      }
    }

    .claim_nr,
    .support_nr,
    .vin_nr,
    .ref_nr {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .QuestionStatus {
    border-radius: 3px;
    float: right;
    padding: 3px;
    text-align: center;
    transform: translateY(-2px);
    width: 2.5em;
    height: 25px;

    & > span {
      color: #fff !important;
      margin-left: 4px;
    }
  }

  .no-label-icon {
    float: right;
  }

  .-status-column {
    height: 35px;
    padding: 0 !important;
    .-status-column_cell {
      color: #000;
      font-size: 0.9em;
      height: 33px;
      padding-top: 5px;
      text-align: center;
    }
  }

  .Pin-Container {
    border-radius: 3px;
    float: right;
    transform: translateY(-2px);
    cursor: pointer;
    .label {
      width: 31.5px !important;
      height: 25px !important;
    }
  }

  .label-icon-container {
    border-radius: 3px;
    transform: translateY(-2px);
    float: right;

    .label {
      justify-content: center;
      align-items: center;
      width: 31.5px !important;
      height: 25px !important;

      .icon {
        margin: 0 0.1em 0 0;
      }
    }
  }

  .Snoozer__Label {
    transform: translateY(-2px);
    width: 31px;
  }

  .pagination-bottom {
    .-pagination {
      .-next {
        button:enabled {
          &:hover {
            background-color: #377730;
            color: white;
          }
          background-color: #46923d;
          color: white;
        }
      }
      .-previous {
        button:enabled {
          &:hover {
            background-color: #377730;
            color: white;
          }
          background-color: #46923d;
          color: white;
        }
      }
    }
  }
}

.sort-icon i.icon {
  cursor: pointer;

  &.inactive-sort-icon {
    opacity: 0.2;
  }
}

.item.selected-sorting-option {
  border-bottom: 1px solid #eee !important;
}

.active.item.selected-sorting-option {
  color: inherit;
  font-weight: 400 !important;
  background-color: #f2f2f2 !important;
}
