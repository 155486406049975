.CustomerDetail {
  padding: 0 1em; }
  .CustomerDetail .row {
    border-bottom: 1px solid #eee; }
    .CustomerDetail .row strong {
      margin-right: 1em; }
  .CustomerDetail .preferred-channel-button {
    min-width: 70px;
    padding: 5px 8px 5px 8px;
    background-color: #fff;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    cursor: pointer; }
    .CustomerDetail .preferred-channel-button .icon {
      font-size: 20px;
      margin-right: 1px; }
    .CustomerDetail .preferred-channel-button span {
      vertical-align: 2px; }
  .CustomerDetail .preferred-channel-button:nth-child(1) {
    margin-right: 10px; }
  .CustomerDetail .active-preferred-channel {
    background-color: #55aa0d;
    color: #fff; }
