.AdvisedCriticalItemsHistory {
  .AppointmentChecklistGroup__header {
    display: flex;
    align-items: center;
  }

  .Loader-Placeholder {
    padding: 1.5em;
    color: #9f9f9f;
    font-size: 1.3em;
    font-weight: 700;
    margin-top: 2em;
    padding: 50px 0;
    text-align: center;
    section {
      margin-top: 25px;
    }

    .icon {
      font-size: 3em;
      margin-bottom: 15px;
    }
  }

  .advised-critical-items-container {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    margin-bottom: 5px;

    .ReactTable {
      padding-bottom: 0.5em;

      .rt-table {
        display: none;
      }
    }
  }

  .advised-critical-items-title {
    background-color: #2285d0;
    color: #fff;
    padding: 0.35em 1em;

    h1 {
      display: inline-block;
      font-size: 1.25em;
      margin-bottom: 0;
      width: auto;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .AppointmentChecklistGroup__header {
    padding-left: 0.4em !important;
    padding-right: 0.4em !important ;
    display: flex !important;
    align-items: center !important;

    &:last-child {
      justify-content: end;
    }

    &__appointmentInfo span {
      width: 58%;

      &:nth-child(2) {
        width: 42%;
      }
    }

    .history-icon-container {
      margin-right: 0.6em;

      .ui.label {
        cursor: pointer;
        width: 31.5px;
        text-align: center;
      }
    }
  }

  .AppointmentChecklistGroup__group-title {
    background-color: #a2a2a2;
    color: #fff;
    padding: 0.35em 0 0.35em 0;

    &.-top-questions-list {
      background-color: red;
      font-size: 1.25em;
      font-weight: 700;
      text-transform: uppercase;
    }

    &.tyre_title {
      display: none;
    }
  }
  .AppointmentChecklistGroup__item {
    border-top: 1px solid #e4e4e4;
    margin: 0 !important;
    padding: 0;
    min-height: 38px;
    color: #584a4a;

    .InlineInput {
      .InlineInputIconTextarea,
      .ui.input input {
        border-bottom: none !important;
      }
    }

    &:last-of-type {
      border-bottom: 1px solid #eee;
    }

    &.Note {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .NoteContainer {
        padding: 0;
        margin: 5px;
        background-color: #fff2ab;
        border: 1px solid #d9d9d9;
        border-radius: 0.3em;

        .AppointmentChecklistGroup__item__question {
          .label {
            width: 40px;
            line-height: 1.5;
            text-align: center;
            border-radius: 0;
            background-color: #f5e17b;
            color: #ce840f;

            i.icon {
              font-size: 1.3em;
            }
          }

          span {
            margin-top: 10px;
          }
        }
      }
    }

    .column {
      font-size: 13px;
      padding: 0.4rem !important;

      &.AppointmentChecklistGroup__item__status {
        width: 3% !important;
        font-size: 0.75em;
      }

      &.AppointmentChecklistGroup__item__appointmentInfo div {
        width: 58%;

        &:nth-child(2) {
          width: 42%;
        }
      }

      &.AppointmentChecklistGroup__item__question {
        .input {
          input,
          .button {
            padding-top: 0 !important;
          }
        }
        .label {
          margin-right: 0.5em;
          width: 30px;
          text-align: center;
          .icon {
            margin: 0;
          }
        }

        .customer-com-checker {
          margin-right: 10px;
          margin-bottom: -5px;
        }
      }
      &.AppointmentChecklistGroup__item__modifiers {
        .AppointmentStatusSelector,
        .Snooze-Container,
        .Pin-Container,
        .ThumbToggleDisabled {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .ThumbToggleDisabled .ui.label:hover {
          cursor: not-allowed;
        }

        .label {
          cursor: pointer;
          width: 31.5px;
          text-align: center;
          margin-right: 0.6em;

          .icon {
            font-size: 1.1em;
            margin: 0;
          }

          @media (max-width: 1500px) {
            & {
              font-size: 0.65em;
              margin-right: 0.32em;
              padding: 7px;
              width: 35px;
            }
          }
        }
        .customer-comm-label {
          cursor: default;
        }
      }
      &.AppointmentChecklistGroup__item__mechanic_note {
        width: 8% !important;
      }
    }
    .AppointmentStatusSelector {
      float: left;
      padding: 7px;
      width: 31.5px;
      height: 27.16px;
      & > span {
        color: #fff !important;
      }
    }
    .AnswerNote {
      margin-top: 0.85em;
      .label {
        margin-right: 0.35em;
        float: left;
        .icon {
          margin: 0;
        }
      }
      .AnswerNote__content {
        float: left;
        margin-left: 0.2em;
        margin-top: 0.2em;
      }
    }
    .AnswerReadyStatus {
      display: inline-block;
      margin-right: 0.1em;
      width: 2em;
      .ui.label {
        cursor: default;
        padding-left: 0;
        padding-right: 0;
        width: 2em;
      }
    }
    .AppointmentChecklistGroup__item__media {
      background-color: #fafafa;
      border-top: 1px solid #f6f6f6;
      padding-top: 5px !important;
      .AppointmentChecklistGroup__item__media__Image-Wrapper {
        display: inline-block;
        margin: 0 10px 10px 0;
        img {
          background-color: #eee;
          cursor: pointer;
          height: 150px;
          width: 266px;
          &:hover {
            opacity: 0.9;
          }
        }
        section {
          background: #fff;
          border: 1px solid #eee;
          border-top: none;
          height: 2em;
          padding: 0.5em;
          .icon {
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }

      .AppointmentChecklistGroup__item__media__Files-Wrapper {
        display: inline-block;
        margin: 0 20px 10px 0;

        .file-wrapper-inner-containers {
          display: flex;
          align-items: flex-end;
        }
      }

      .AppointmentChecklistGroup__item__media__Video-Wrapper {
        display: inline-block;
        margin: 0 10px 10px 0;
        video {
          height: 150px;
          width: auto;
          transform: translateY(7px);
          position: relative;
          bottom: 7px;
        }
        section {
          background: #fff;
          border: 1px solid #eee;
          border-top: none;
          height: 2em;
          padding: 0.5em;
          .icon {
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
